import { PayloadAction } from "@reduxjs/toolkit";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { deleteListItemUsingForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import { setObjectFieldsUndefined } from "../../../../utils/setObjectsFieldsUndefined";
import { FunnelType } from "../../types/funnelTypes";
import {
  FunnelInitialStateType,
  FunnelStoreTypesEnum,
} from "../../types/initialStateTypes";

const setUndefinedWhenLeavingPageReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<{
    entityType: Partial<FunnelStoreTypesEnum[]>;
  }>
) => {
  setObjectFieldsUndefined({
    obj: state,
    entitiesList: action.payload.entityType,
  });
};
const addNewFunnelToFunnelsListSSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelType>
) => {
  if (state.funnelsList) {
    state.funnelsList.push(action.payload);
  }
};
const updateFunnelNameInFunnelsListSSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelType>
) => {
  if (state.funnelsList) {
    const updatedFunnel = findElementInListUsingBuiltInFindMethod({
      list: state.funnelsList,
      lookForParam: "funnelId",
      lookForParamValue: action.payload.funnelId,
    });

    if (updatedFunnel) {
      updatedFunnel.funnelName = action.payload.funnelName;
    }
  }
};
const updateFunnelWithFunnelStepsWithoutRelatedLeadsSSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelType>
) => {
  if (
    state.funnelWithFunnelStepsWithoutRelatedEntities &&
    state.funnelWithFunnelStepsWithoutRelatedEntities.funnelId ===
      action.payload.funnelId
  ) {
    state.funnelWithFunnelStepsWithoutRelatedEntities.funnelName =
      action.payload.funnelName;
  }
};
const deleteFunnelFromFunnelsListSSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<number>>
) => {
  if (state.funnelsList) {
    const updatedFunnelsListAndDeletedFunnel =
      deleteListItemUsingForAndSpliceMethods({
        list: state.funnelsList,
        filterParam: "funnelId",
        filterParamValue: action.payload.identifier,
      });

    if (updatedFunnelsListAndDeletedFunnel) {
      state.funnelsList = updatedFunnelsListAndDeletedFunnel.updatedArray;
    }
  }
};

export const funnelReducers = {
  setUndefinedWhenLeavingPageReducer,
  addNewFunnelToFunnelsListSSUReducer,
  updateFunnelNameInFunnelsListSSUReducer,
  updateFunnelWithFunnelStepsWithoutRelatedLeadsSSUReducer,
  deleteFunnelFromFunnelsListSSUReducer,
};
