import { AppDispatch, RootState } from "../../../../../store";
import { showNotification } from "../../../../notification/store/slice";
import { deleteRecordSSU } from "../../../../record/store/slice";
import { deleteTask } from "../../../../user/store/slice";
import { RecordTypeWithRecordsContainerId } from "../../../../record/types/recordTypes";
import { NotificationTypeEnum } from "../../../../notification/types/notificationTypes";
import {
  RelatedEntityDataType,
  RelatedEntityTypeEnum,
} from "../../../../../commonTypes/relatedEntityTypes";
import { RelatedEntityDataWIthoutRecordsContainerId } from "../../../types/recordTypes/recordDataTypes/recordDataTypes";

export const deleteRecordSystemUpdateHandler = (args: {
  dispatch: AppDispatch;
  state: RootState;
  // data
  recordRelatedEntityData: RelatedEntityDataWIthoutRecordsContainerId;
  recordData: RecordTypeWithRecordsContainerId;
  // flags
  recordIsRelatedToOpenedLeadOrDeal: boolean;
  isTaskFlag: boolean;
  taskIsRelatedToUserFlag: boolean;
}) => {
  const leadData =
    args.recordRelatedEntityData.relatedEntityType ===
    RelatedEntityTypeEnum.lead
      ? {
          id: args.recordRelatedEntityData.relatedEntityId,
          name: args.recordRelatedEntityData.relatedEntityName,
        }
      : null;
  const dealData =
    args.recordRelatedEntityData.relatedEntityType ===
    RelatedEntityTypeEnum.deal
      ? {
          id: args.recordRelatedEntityData.relatedEntityId,
          name: args.recordRelatedEntityData.relatedEntityName,
        }
      : null;

  if (args.isTaskFlag && args.taskIsRelatedToUserFlag) {
    args.dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.notification,
        notificationText: `Задача была удалена.
              Текст задачи: ${args.recordData.recordText}`,
      })
    );
    args.dispatch(
      deleteTask({
        completionStatus: args.recordData.completionStatus!,
        executionTime: args.recordData.executionTime!,
        id: args.recordData.id,
        recordText: args.recordData.recordText,
        leadData: leadData,
        dealData: dealData,
      })
    );
  }
  if (args.recordIsRelatedToOpenedLeadOrDeal) {
    args.dispatch(deleteRecordSSU({ identifier: args.recordData.id }));
  }
};
