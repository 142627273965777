import _history from "../../../../const/history";
import { UpdateTypeEnum, RoutersArgsType } from "../../types/types";
import { recordDataSSUTypePredicate } from "../../types/recordTypes/recordDataTypes/predicates";
import { createRecordSystemUpdateHandler } from "./recordHandlers/create";
import { updateRecordSystemUpdateHandler } from "./recordHandlers/update";
import { deleteRecordSystemUpdateHandler } from "./recordHandlers/delete";

export const routeRecordUpdates = (args: RoutersArgsType) => {
  if (!recordDataSSUTypePredicate(args.eventData.ssuData)) {
    return;
  }

  const state = args.getState();

  const recordIsRelatedToOpenedLeadOrDealFlag =
    args.eventData.ssuData.recordData.recordsContainerId ===
    state.record.containerId;

  const isTaskFlag =
    Boolean(args.eventData.ssuData.recordData.completionStatus) &&
    Boolean(args.eventData.ssuData.recordData.executionTime);
  const taskIsRelatedToUserFlag =
    state.user.user?.username ===
    args.eventData.ssuData.recordData.personInChargeUsername;

  if (
    !recordIsRelatedToOpenedLeadOrDealFlag &&
    (!isTaskFlag || !taskIsRelatedToUserFlag)
  ) {
    return;
  }

  const recordRelatedEntityData = args.eventData.ssuData.relatedEntityData;
  const recordData = args.eventData.ssuData.recordData;

  switch (args.eventData.eventType) {
    case UpdateTypeEnum.create:
      createRecordSystemUpdateHandler({
        dispatch: args.dispatch,
        state: state,
        // data
        recordRelatedEntityData: recordRelatedEntityData,
        recordData: recordData,
        // flags
        recordIsRelatedToOpenedLeadOrDeal:
          recordIsRelatedToOpenedLeadOrDealFlag,
        isTaskFlag: isTaskFlag,
        taskIsRelatedToUserFlag: taskIsRelatedToUserFlag,
      });
      break;

    case UpdateTypeEnum.update:
      updateRecordSystemUpdateHandler({
        dispatch: args.dispatch,
        state: state,
        // data
        recordRelatedEntityData: recordRelatedEntityData,
        recordData: recordData,
        // flags
        recordIsRelatedToOpenedLeadOrDeal:
          recordIsRelatedToOpenedLeadOrDealFlag,
        isTaskFlag: isTaskFlag,
        taskIsRelatedToUserFlag: taskIsRelatedToUserFlag,
      });
      break;

    case UpdateTypeEnum.delete:
      deleteRecordSystemUpdateHandler({
        dispatch: args.dispatch,
        state: state,
        // data
        recordRelatedEntityData: recordRelatedEntityData,
        recordData: recordData,
        // flags
        recordIsRelatedToOpenedLeadOrDeal:
          recordIsRelatedToOpenedLeadOrDealFlag,
        isTaskFlag: isTaskFlag,
        taskIsRelatedToUserFlag: taskIsRelatedToUserFlag,
      });
      break;

    default:
      const _exhaustiveCheck: never = args.eventData.eventType;
      throw new Error("Invalid event type");
  }
};
