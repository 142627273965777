import { DefaultTheme } from "styled-components";
import { themeCommon } from "./baseThemeCommon";

export const baseThemeLight: DefaultTheme = {
  _themeName: "light",

  ...themeCommon,

  colorDarkTransparent: "#0d182148",
  colorLightTransparent: "#49494948",

  colorPrimaryBackground: "#c2bfbe",
  colorSecondaryBackground: "#a1a09e",
  colorPrimaryText: "#413d3a",
  colorGreen: "#246b53",
  colorRed: "#a31a1a",
  colorYellow: "#c29c2b",
  colorPurple: "#763e97",
  colorNone: "none",
  colorWhite: "#b9b2a9",
};
