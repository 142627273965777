import snakeize from "../../../utils/snakeize";
import { axiosInstance } from "../../../api/httpCommon";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import {
  CreateCustomFieldType,
  DeleteCustomFieldType,
  UpdateCustomFieldRequestType,
  UpdateCustomFieldType,
} from "../types/customFieldTypes";

const createCustomField = async (
  args: CreateCustomFieldType
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedData = snakeize(args.createData);

  const response = await axiosInstance.post<
    CreateUpdateDeleteReturnOneValueType<number>
  >(
    `custom-field/create-custom-field?records_container_id=${args.recordsContainerId}`,
    snakeizedData
  );

  return response.data;
};

const updateCustomField = async (
  args: UpdateCustomFieldRequestType
): Promise<void> => {
  const snakeizedData = { field_data: args.fieldData };

  await axiosInstance.patch(
    `custom-field/update-custom-field/${args.id}?records_container_id=${args.recordsContainerId}`,
    snakeizedData
  );
};

const deleteCustomField = async (
  args: DeleteCustomFieldType
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const response = await axiosInstance.delete<
    CreateUpdateDeleteReturnOneValueType<number>
  >(`custom-field/delete-custom-field/${args.id}?records_container_id=${args.recordsContainerId}`);

  return response.data;
};

export const customFieldRequests = {
  createCustomField,
  updateCustomField,
  deleteCustomField,
};
