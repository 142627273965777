import { AppDispatch, RootState } from "../../../../../store";
import { CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType } from "../../../types/funnelTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes";
import {
  updateLeadFunnelStepSSU,
  updateLeadItemFunnelAndFunnelStepNamesSSU,
} from "../../../../lead/store/slice";
import {
  updateFunnelStepNameWithoutRelatedLeadsSSU,
  updateFunnelStepsOrderAtFunnelStepsWithoutRelatedLeadsSSU,
  updateFunnelStepsOrderAtFunnelStepsWithRelatedLeadsSSU,
  updateFunnelStepWithRelatedLeadsNameSSU,
} from "../../../../funnel/store/slice";
import {
  LeadFunnelStepType,
  LeadFunnelStepWithRelatedFunnelType,
} from "../../../../lead/types/leadFunnelTypes";
import { FunnelStepUuidAndNameType } from "../../../../funnel/types/funnelStepTypes";

export const updateFunnelStepWithoutRelatedLeadSystemUpdateHandler = (args: {
  eventData: CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType;
  dispatch: AppDispatch;
  state: RootState;
}) => {
  if (!args.eventData.ssuData.funnelStepsList) {
    // FUNNEL STEP NAME HAS BEEN UPDATED
    if (
      args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities &&
      args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities.funnelId ===
        args.eventData.ssuData.funnelId
    ) {
      // "edit funnel" page is opened
      const updateFunnesStepUuidAndName: FunnelStepUuidAndNameType = {
        funnelStepUuid:
          args.eventData.ssuData.funnelStepUuid,
        funnelStepName:
          args.eventData.ssuData.funnelStepName,
      };

      args.dispatch(
        updateFunnelStepNameWithoutRelatedLeadsSSU(updateFunnesStepUuidAndName)
      );
    } else if (
      args.state.funnel.funnelWithFunnelStepsWithRelatedEntities &&
      args.state.funnel.funnelWithFunnelStepsWithRelatedEntities.funnelId ===
        args.eventData.ssuData.funnelId
    ) {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      
      // "funnels and funnel steps" page is opened
      // (funnel step with related leads)
      const updateFunnesStepIdAndName: FunnelStepUuidAndNameType = {
        funnelStepUuid:
          args.eventData.ssuData.funnelStepUuid,
        funnelStepName:
          args.eventData.ssuData.funnelStepName,
      };
      args.dispatch(
        updateFunnelStepWithRelatedLeadsNameSSU(updateFunnesStepIdAndName)
      );
    } else if (
      args.state.lead.lead &&
      args.state.lead.lead.funnelStep &&
      args.state.lead.lead.funnelStep.funnelStepUuid ===
        args.eventData.ssuData.funnelStepUuid
    ) {
      // "lead" page is opened
      const updateLeadFunnelStepData: LeadFunnelStepWithRelatedFunnelType = {
        funnelStepUuid: args.state.lead.lead.funnelStep.funnelStepUuid,
        funnelStepName:
          args.eventData.ssuData.funnelStepName,
        funnel: {
          funnelId: args.state.lead.lead.funnelStep.funnel.funnelId,
          funnelName: args.state.lead.lead.funnelStep.funnel.funnelName,
        },
      };

      args.dispatch(updateLeadFunnelStepSSU(updateLeadFunnelStepData));
    } else if (args.state.lead.leadListData?.leadsList) {
      const updateLeadListItemFunnelStepData: LeadFunnelStepType = {
        funnelStepUuid:
          args.eventData.ssuData.funnelStepUuid,
        funnelStepName:
          args.eventData.ssuData.funnelStepName,
      };

      args.dispatch(
        updateLeadItemFunnelAndFunnelStepNamesSSU(
          updateLeadListItemFunnelStepData
        )
      );
    }
  }
  // FUNNEL STEPS ORDER HAS BEEN UPDATED
  if (args.eventData.ssuData.funnelStepsList) {
    if (
      args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities &&
      args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities.funnelId ===
        args.eventData.ssuData.funnelId
    ) {
      // "edit funnel" page is opened
      args.dispatch(
        updateFunnelStepsOrderAtFunnelStepsWithoutRelatedLeadsSSU(
          args.eventData.ssuData.funnelStepsList
        )
      );
    } else if (
      args.state.funnel.funnelWithFunnelStepsWithRelatedEntities &&
      args.state.funnel.funnelWithFunnelStepsWithRelatedEntities.funnelId ===
        args.eventData.ssuData.funnelId
    ) {
      // "funnels and funnel steps" page is opened
      // (funnel step with related leads)
      const updateFunnelStepsOrderData = {
        updatedFunnelStepUuid:
          args.eventData.ssuData.funnelStepUuid,
        updatedFunnelStepsArray: args.eventData.ssuData.funnelStepsList,
      };
      args.dispatch(
        updateFunnelStepsOrderAtFunnelStepsWithRelatedLeadsSSU(
          updateFunnelStepsOrderData
        )
      );
    }
  }
};
