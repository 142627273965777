import { createAsyncThunk } from "@reduxjs/toolkit";
import { funnelRequests } from "../../requests/funnelRequests";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { RelatedEntityArgsType } from "../../../../commonTypes/relatedEntityTypes";
import { AppDispatch } from "../../../../store";
import { showNotification } from "../../../notification/store/slice";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import {
  CreateFunnelType,
  FunnelType,
  FunnelWithRelatedFunnelStepsWithoutRelatedEntitiesType,
} from "../../types/funnelTypes";

export const createFunnelAsyncThunk = createAsyncThunk<
  undefined,
  CreateFunnelType,
  { rejectValue: RejectWithValueErrorType }
>("funnel/createFunnel", async (args, { rejectWithValue, dispatch }) => {
  try {
    await funnelRequests.createFunnel(args);
  } catch (error: any) {
    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText:
          "Произошла ошибка. Пожалуйста перезагрузите страницу или свяжитесь с администратором.",
      })
    );
    return rejectWithValue(error.message);
  }
});

export const getFunnelsListAsycnThunk = createAsyncThunk<
  FunnelType[],
  RelatedEntityArgsType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("funnel/getFunnelsList", async (args, { rejectWithValue, dispatch }) => {
  try {
    const funnels = await funnelRequests.getFunnelsList({
      relatedEntity: args.relatedEntity,
    });

    return funnels;
  } catch (error: any) {
    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText:
          "Произошла ошибка. Пожалуйста перезагрузите страницу или свяжитесь с администратором.",
      })
    );
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});

export const getFunnelWithRelatedFunnelStepsWithoutRelatedLeadsAsyncThunk =
  createAsyncThunk<
    FunnelWithRelatedFunnelStepsWithoutRelatedEntitiesType,
    { funnelId: number },
    { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
  >(
    "funnel/getFunnelWithRelatedFunnelStepsWithoutRelatedLeadsAsyncThunk",
    async (args, { rejectWithValue, dispatch }) => {
      const stringedFunnelIdToNumber = Number(args.funnelId);

      try {
        const response =
          await funnelRequests.getFunnelWithRelatedFunnelStepsWithoutRelatedEntities(
            stringedFunnelIdToNumber
          );

        return response;
      } catch (error: any) {
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText:
            "Произошла ошибка. Пожалуйста перезагрузите страницу или свяжитесь с администратором.",
        });
        return rejectWithValue({
          message: error.message,
          status: error.response?.status,
          errorDetails: error.response?.data?.error,
        });
      }
    }
  );

export const updateFunnelNameAsyncThunk = createAsyncThunk<
  string,
  FunnelType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "funnel/updateFunnelNameAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await funnelRequests.updateFunnel({
        funnelId: args.funnelId,
        funnelData: { funnelName: args.funnelName },
      });

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Имя воронки успешно обновлено.",
        })
      );

      return args.funnelName;
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Воронка была удалена другим пользователем",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const deleteFunnelAsyncThunk = createAsyncThunk<
  undefined,
  CreateUpdateDeleteReturnOneValueType<number>,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("funnel/deleteFunnel", async (args, { rejectWithValue, dispatch }) => {
  try {
    await funnelRequests.deleteFunnel({
      funnelId: args.identifier,
    });
    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.warning,
        notificationText: "Воронка и все её шаги удалены.",
      })
    );
    return undefined;
  } catch (error: any) {
    if (
      error.response.status === 404 &&
      error.response.data.error === "Data does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Воронка была удалена другим пользователем",
        })
      );
    }

    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});
