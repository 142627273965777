import { PayloadAction } from "@reduxjs/toolkit";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import {
  CustomFieldType,
  UpdateCustomFieldType,
} from "../../types/customFieldTypes";
import { CustomFieldInitialStateType } from "../../types/initialStateType";

const createCustomFieldExtraReducer = (
  state: CustomFieldInitialStateType,
  action: PayloadAction<CustomFieldType>
) => {
  if (!state.customFields) {
    throw new Error("Lead has not bean loaded");
  }
  state.customFields.push(action.payload);
};

const updateCustomFieldExtraReducer = (
  state: CustomFieldInitialStateType,
  action: PayloadAction<UpdateCustomFieldType>
) => {
  if (!state.customFields) {
    throw new Error("Lead has not bean loaded");
  }
  const leadCustomField = state.customFields.find(
    (field) => field.id === action.payload.id
  );
  if (leadCustomField) {
    leadCustomField.fieldData = action.payload.fieldData;
  }
};

const deleteCustomFieldExtraReducer = (
  state: CustomFieldInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<number>>
) => {
  if (!state.customFields) {
    throw new Error("Lead has not bean loaded");
  }
  const filteredFields = state.customFields.filter(
    (field) => field.id !== action.payload.identifier
  );

  state.customFields = filteredFields;
};

export const customFieldExtraReducers = {
  createCustomFieldExtraReducer,
  updateCustomFieldExtraReducer,
  deleteCustomFieldExtraReducer,
};
