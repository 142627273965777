import { createSlice } from "@reduxjs/toolkit";
import { dealIinitialState } from "./initialState";
import { dealReducers } from "./reducers/dealReducers";
import { dealExtraReducers } from "./extraReducers/dealExtraReducers";
import { updateDealPersonsInChargeAsyncThunk } from "./asyncThunks/dealPersonsInChargeAsyncThunks";
import { dealPersonInChargeReducers } from "./extraReducers/dealPersonsInChargeExtraReducers";
import {
  getDealByIdAsyncThunk,
  updateDealAsyncThunk,
} from "./asyncThunks/dealAsyncThunks";

const dealSlice = createSlice({
  name: "deal",
  initialState: dealIinitialState,
  reducers: {
    setUndefinedWhenLeavingPage:
      dealReducers.setUndefinedWhenLeavingPageReducer,
    setDealContactsIdsReducer: dealReducers.setDealContactsIdsReducer,

    // SSU reducer
    // create deal reducers
    addNewDealToDealDealsListSSU:
      dealReducers.addNewDealToDealDealsListSSUReducer,
    // update deal reducers
    updateDealDataSSU: dealExtraReducers.updateDealExtraFulfilledExtraReducer,
    updateDealAtDealDealsPreviewsListSSU:
      dealReducers.updateDealAtDealDealsPreviewsListSSUReducer,
    // delete deal reducers
    deleteDealFromDealDealsListSSU:
      dealReducers.deleteDealFromDealDealsListSSUReducer,
    // update deal persons in charge ssu reducers
    updateDealPersonsInChargeSSU:
      dealPersonInChargeReducers.updateDealPersonsInChargeExtraReducer,
  },

  extraReducers: (builder) => {
    builder.addCase(
      getDealByIdAsyncThunk.fulfilled,
      dealExtraReducers.getDealByIdExtraReducer
    );
    builder.addCase(
      updateDealAsyncThunk.fulfilled,
      dealExtraReducers.updateDealExtraFulfilledExtraReducer
    );

    // dealPersonsInChargeExtraReducers
    builder.addCase(
      updateDealPersonsInChargeAsyncThunk.fulfilled,
      dealPersonInChargeReducers.updateDealPersonsInChargeExtraReducer
    );
  },
});

export const {
  setUndefinedWhenLeavingPage,
  setDealContactsIdsReducer,

  addNewDealToDealDealsListSSU,
  updateDealDataSSU,
  updateDealAtDealDealsPreviewsListSSU,
  deleteDealFromDealDealsListSSU,
  updateDealPersonsInChargeSSU,
} = dealSlice.actions;

export default dealSlice.reducer;
