import { PayloadAction } from "@reduxjs/toolkit";
import { LeadInitialStateType } from "../../types/initialState";
import { LeadFunnelStepWithRelatedFunnelType } from "../../types/leadFunnelTypes";

const updateLeadFunnelStepSSUReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<LeadFunnelStepWithRelatedFunnelType | null>
) => {
  if (!state.lead) {
    return;
  }
  state.lead.funnelStep = action.payload;
};

export const leadFunnelStepReducers = {
  updateLeadFunnelStepSSUReducer,
};
