import { PayloadAction } from "@reduxjs/toolkit";
import { DealInitialStateType } from "../../types/initialStateType";
import { deleteListItemUsingForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";
import {
  DealPreviewType,
  UpdateDealPreviewSUUType,
} from "../../../lead/types/leadDealTypes";

const setUndefinedWhenLeavingPageReducer = (
  state: DealInitialStateType,
  action: PayloadAction<undefined>
) => {
  state.deal = undefined;
};

const setDealContactsIdsReducer = (
  state: DealInitialStateType,
  action: PayloadAction<number[]>
) => {
  if (state.deal) {
    state.deal.dealContactsIds = action.payload;
  }
};

const addNewDealToDealDealsListSSUReducer = (
  state: DealInitialStateType,
  action: PayloadAction<DealPreviewType>
) => {
  if (!state.deal) {
    return;
  }
  state.deal.dealsOfTheSameLeadPreviews.push(action.payload);
};

const updateDealAtDealDealsPreviewsListSSUReducer = (
  state: DealInitialStateType,
  action: PayloadAction<UpdateDealPreviewSUUType>
) => {
  if (!state.deal) {
    return;
  }
  const dealForUpdate = findElementInListUsingBuiltInFindMethod({
    list: state.deal.dealsOfTheSameLeadPreviews,
    lookForParam: "id",
    lookForParamValue: action.payload.id,
  });

  if (!dealForUpdate) {
    return;
  }

  updateObjectDataByNewDataContainsNullAndUndefined({
    data: dealForUpdate,
    newUpdateData: {
      funnelStep: action.payload.funnelStep,
      name: action.payload.name,
    },
  });
};

const deleteDealFromDealDealsListSSUReducer = (
  state: DealInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<number>>
) => {
  if (!state.deal) {
    return;
  }
  const deletedDealPreviewAndUpdatedDealPreviewsArray =
    deleteListItemUsingForAndSpliceMethods({
      filterParam: "id",
      filterParamValue: action.payload.identifier,
      list: state.deal.dealsOfTheSameLeadPreviews,
    });

  if (!deletedDealPreviewAndUpdatedDealPreviewsArray) {
    return;
  }

  state.deal.dealsOfTheSameLeadPreviews =
    deletedDealPreviewAndUpdatedDealPreviewsArray.updatedArray;
};

export const dealReducers = {
  setUndefinedWhenLeavingPageReducer,
  setDealContactsIdsReducer,
  //
  addNewDealToDealDealsListSSUReducer,
  updateDealAtDealDealsPreviewsListSSUReducer,
  deleteDealFromDealDealsListSSUReducer,
};
