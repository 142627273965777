import { DefaultTheme } from "styled-components";
import { themeCommon } from "./baseThemeCommon";

export const baseThemeDark: DefaultTheme = {
  _themeName: "dark",

  ...themeCommon,
  
  
  colorPrimaryBackground: "#0e0f0f",
  colorPrimaryText: "#8a8988",
  colorSecondaryBackground: "#292c2e",
  colorGreen: "#0e261e",
  colorRed: "#670e12",
  colorYellow: "#2d2304",
  colorPurple: "#1e1026",
  colorNone: "none",
  colorWhite: "#b9b2a9",
};
