import { NotificationInitialStateType } from "../types/initialStateTypes";
import {
  NotificationColorEnum,
  NotificationTitleEnum,
  NotificationTypeEnum,
} from "../types/notificationTypes";

export const notificationInitialState: NotificationInitialStateType = {
  notification: {
    notificationTitle: NotificationTitleEnum.notification,
    notificationBackgroundColor: NotificationColorEnum.notification,
    notificationText: "",
    isVisible: false,
  },
};
