import { AppDispatch, RootState } from "../../../../../store";
import { addNewDealToDealDealsListSSU } from "../../../../deal/store/slice";
import { addNewDealToLeadDealsListSSU } from "../../../../lead/store/slice";
import { CreateDealSSUType } from "../../../types/dealTypes/dealTypes";

export const createDealSystemUpdateHandler = (args: {
  state: RootState;
  dispatch: AppDispatch;
  ssuData: CreateDealSSUType;
}) => {
  
  console.log(args);
  
  if (args.state.lead.lead && args.state.lead.lead.id === args.ssuData.leadId) {
    args.dispatch(
      addNewDealToLeadDealsListSSU({
        id: args.ssuData.id,
        name: args.ssuData.name,
        funnelStep: args.ssuData.funnelStep,
      })
    );
    return;
  }
  if (
    args.state.deal.deal &&
    args.state.deal.deal.leadId === args.ssuData.leadId
  ) {
    args.dispatch(addNewDealToDealDealsListSSU(args.ssuData));
    return;
  }
};

// Может появиться в списке сделок лида
