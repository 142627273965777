import { createSlice } from "@reduxjs/toolkit";
import { positionInitialState } from "./initialState";
import { positionFulfilledExtraReducers } from "./extraReducers/fulfilledExtraReducers";
import { positionRejectedExtraReducers } from "./extraReducers/rejectedExtraReducers";
import {
  createPositionAsyncThunk,
  deletePositionAsyncThunk,
  getPositionsListAsyncThunk,
  updatePositionAsyncThunk,
} from "./asyncThunks";

const positionSlice = createSlice({
  name: "position",
  initialState: positionInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getPositionsListAsyncThunk.fulfilled,
      positionFulfilledExtraReducers.getPositionsListFulfilledExtraReducer
    );
    builder.addCase(
      createPositionAsyncThunk.fulfilled,
      positionFulfilledExtraReducers.createPositionFulfilledExtraReducer
    );
    builder.addCase(
      updatePositionAsyncThunk.fulfilled,
      positionFulfilledExtraReducers.updatePositionFulfilledExtraReducer
    );
    builder.addCase(
      updatePositionAsyncThunk.rejected,
      positionRejectedExtraReducers.updateDeletePositionExtraReducer
    );
    builder.addCase(
      deletePositionAsyncThunk.fulfilled,
      positionFulfilledExtraReducers.deletePositionFulfilledExtraReducer
    );
    builder.addCase(
      deletePositionAsyncThunk.rejected,
      positionRejectedExtraReducers.updateDeletePositionExtraReducer
    );
  },
});

export const {} = positionSlice.actions;

export default positionSlice.reducer;
