import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "../apps/user/store/slice";
import leadReducer from "../apps/lead/store/slice";
import dealReducer from "../apps/deal/store/slice";
import funnelReducer from "../apps/funnel/store/slice";
import notificationReducer from "../apps/notification/store/slice";
import contactReducer from "../apps/contact/store/slice";
import customFieldReducer from "../apps/customField/store/slice";
import recordReducer from "../apps/record/store/slice";
import positionReducer from "../apps/position/store/slice";
import { Socket } from "../apps/webSocket/utils/webSocketManager";
import { notificationWebSocketsMiddleware } from "./middleware/notificationWebSocketsMiddleware";

const rootReducer = combineReducers({
  user: userReducer,
  lead: leadReducer,
  deal: dealReducer,
  funnel: funnelReducer,
  notification: notificationReducer,
  contact: contactReducer,
  customField: customFieldReducer,
  record: recordReducer,
  position: positionReducer,
  // webSocketNotifications: webSocketNotificationReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      notificationWebSocketsMiddleware(new Socket())
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
