import { DeleteDealType } from "../../../deal/types/dealTypes";
import {
  CreateDealSSUType,
  UpdateDealSSUType,
} from "../../types/dealTypes/dealTypes";
import { RoutersArgsType, UpdateTypeEnum } from "../../types/types";
import { createDealSystemUpdateHandler } from "./dealDataRouteHandlers/create";
import { deleteDealSystemUpdateHandler } from "./dealDataRouteHandlers/delete";
import { updateDealSystemUpdateHandler } from "./dealDataRouteHandlers/update";

export const routeDealUpdates = (args: RoutersArgsType) => {
  const state = args.getState();
  

  switch (args.eventData.eventType) {
    case UpdateTypeEnum.create:
      createDealSystemUpdateHandler({
        dispatch: args.dispatch,
        ssuData: args.eventData.ssuData as CreateDealSSUType,
        state: state,
      });
      break;
    case UpdateTypeEnum.update:
      updateDealSystemUpdateHandler({
        dispatch: args.dispatch,
        ssuData: args.eventData.ssuData as UpdateDealSSUType,
        state: state,
      });
      break;
    case UpdateTypeEnum.delete:
      deleteDealSystemUpdateHandler({
        dispatch: args.dispatch,
        ssuData: args.eventData.ssuData as DeleteDealType,
        state: state,
      });
      break;
    default:
      const _exhaustiveCheck: never = args.eventData.eventType;
      throw new Error("Invalid event type");
  }
};
