import { RelatedEntityDataType } from "../../../commonTypes/relatedEntityTypes";

export enum RecordTypeEnum {
  logRecord = "Системное сообщение",
  note = "Заметка",
  task = "Задача",
}

export enum RecordTaskCompletionStatusEnum {
  in_progress = "Выполняется",
  completed = "Выполнена",
  overdue = "Просрочена",
}

export type CreateRecordType = {
  recordType: RecordTypeEnum;
  recordText: string;
  recordsContainerId: number;

  executionTime: string | null;
  personInChargeUsername: string | null;
};

type CreateRecordTypeWithoutRecordsContainerId = Omit<
  CreateRecordType,
  "recordsContainerId"
>;

export type UpdateRecordDataType = {
  recordText?: string;
  completionStatus?: RecordTaskCompletionStatusEnum;
  executionTime?: string;
  personInChargeUsername?: string;
};

export type DeleteRecordType = {
  recordId: number;
  recordType: RecordTypeEnum;
};

export type RecordType = CreateRecordTypeWithoutRecordsContainerId & {
  id: number;
  authorUsername: string | null;
  creationTime: string;
  completionStatus: RecordTaskCompletionStatusEnum | null;
};

export type RecordTypeWithRecordsContainerId = CreateRecordType & {
  id: number;
  authorUsername: string | null;
  creationTime: string;
  completionStatus: RecordTaskCompletionStatusEnum | null;
};

export type CreateRecordWithRelatedEntityType = {
  recordData: CreateRecordType;
  relatedEntityData: RelatedEntityDataType;
};

export type UpdateRecordWithRelatedEntityType = {
  recordData: UpdateRecordDataType;
  recordId: number;
  recordType: RecordTypeEnum;
  relatedEntityData: RelatedEntityDataType;
};

export type DeleteRecordWithRelatedEntityType = {
  recordData: DeleteRecordType;
  relatedEntityData: RelatedEntityDataType;
};

export type RecordTypeWithRelatedEntityType = {
  recordData: RecordType;
  relatedEntityData: RelatedEntityDataType;
};
