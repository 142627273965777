import { AppDispatch, RootState } from "../../../../../store";
import { CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType } from "../../../types/funnelTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes";
import {
  addFunnelStepToFunnelStepsWithoutRelatedLeadsArraySSU,
  addNewFunnelStepWithRelatedLeadsToFunnelStepsArraySSU,
} from "../../../../funnel/store/slice";

export const createFunnelStepWithoutRelatedLeadSystemUpdateHandler = (args: {
  eventData: CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType;
  dispatch: AppDispatch;
  state: RootState;
}) => {
  if (
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities &&
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities?.funnelId ===
      args.eventData.ssuData.funnelId
  ) {
    args.dispatch(
      addNewFunnelStepWithRelatedLeadsToFunnelStepsArraySSU({
        funnelStepUuid: args.eventData.ssuData.funnelStepUuid,
        funnelStepName: args.eventData.ssuData.funnelStepName,
        leads: [],
        deals: [],
      })
    );
  } else if (
    args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities &&
    args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities.funnelId ===
      args.eventData.ssuData.funnelId
  ) {
    if (!args.eventData.ssuData.funnelStepsList) {
      return;
    }
    args.dispatch(
      addFunnelStepToFunnelStepsWithoutRelatedLeadsArraySSU(
        args.eventData.ssuData.funnelStepsList
      )
    );
  }
};
