import { LeadInitialStateType } from "../types/initialState";
import { LeadsListAndPaginationDataType } from "../types/leadTypes";

const leadIinitialState: LeadInitialStateType = {
  lead: undefined,
  leadListData: {
    leadsList: undefined,
    paginationData: undefined,
  },
};

export { leadIinitialState };
