import { PayloadAction } from "@reduxjs/toolkit";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { deleteListItemUsingForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";
import { ContactType, UpdateContactDataResponseType } from "../../types/contactTypes";
import { ContactInitialStateType } from "../../types/initialStateType";

const createContactExtraReducer = (
  state: ContactInitialStateType,
  action: PayloadAction<ContactType>
) => {
  if (!state.contacts) {
    throw new Error("Contacts has not bean loaded");
  }

  state.contacts.push(action.payload);
};

const updateContactExtraReducer = (
  state: ContactInitialStateType,
  action: PayloadAction<UpdateContactDataResponseType>
) => {
  if (!state.contacts) {
    throw new Error("Contacts has not bean loaded");
  }

  const updatedContact = findElementInListUsingBuiltInFindMethod({
    list: state.contacts,
    lookForParam: "id",
    lookForParamValue: action.payload.id,
  });

  if (updatedContact === null) {
    return;
  }

  updateObjectDataByNewDataContainsNullAndUndefined({
    data: updatedContact,
    newUpdateData: action.payload,
  });
};

const deleteContactExtraReducer = (
  state: ContactInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<number>>
) => {
  if (!state.contacts) {
    throw new Error("Lead has not bean loaded");
  }
  const filteredContactList =
    deleteListItemUsingForAndSpliceMethods<ContactType>({
      list: state.contacts,
      filterParam: "id",
      filterParamValue: action.payload.identifier,
    });
  if (filteredContactList) {
    state.contacts = filteredContactList.updatedArray;
  }
};

const updateRelatedEntityContactsListExtraReducer = (
  state: ContactInitialStateType,
  action: PayloadAction<ContactType[]>
) => {
  state.contacts = action.payload;
};

export const contactFulfilledExtraReducers = {
  createContactExtraReducer,
  updateContactExtraReducer,
  deleteContactExtraReducer,
  updateRelatedEntityContactsListExtraReducer,
};
