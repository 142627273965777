import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  RelatedEntityDataType,
  RelatedEntityTypeEnum,
} from "../../../../commonTypes/relatedEntityTypes";
import { RootState, AppDispatch } from "../../../../store";
import { UserTaskType } from "../../../user/types/userTasksTypes";
import {
  RecordTaskCompletionStatusEnum,
  RecordType,
  RecordTypeEnum,
} from "../../types/recordTypes";

const _generateLeadDealData = (args: {
  relatedEntityData: RelatedEntityDataType;
}) => {
  const relatedEntityData = {
    id: args.relatedEntityData.relatedEntityId,
    name: args.relatedEntityData.relatedEntityName,
  };
  const leadData =
    args.relatedEntityData.relatedEntityType === RelatedEntityTypeEnum.lead
      ? relatedEntityData
      : null;
  const dealData =
    args.relatedEntityData.relatedEntityType === RelatedEntityTypeEnum.deal
      ? relatedEntityData
      : null;

  return { leadData, dealData };
};

export const createUpdateDeleteUserTaskUtil = (args: {
  getState: () => RootState;
  dispatch: AppDispatch;
  record: RecordType;
  relatedEntityData: RelatedEntityDataType;
  reducer: ActionCreatorWithPayload<
    UserTaskType,
    "user/createTask" | "user/updateTask" | "user/deleteTask"
  >;
}) => {
  const username = args.getState().user.user?.username;

  if (
    args.record.recordType !== RecordTypeEnum.task ||
    args.record.personInChargeUsername !== username
  ) {
    return;
  }

  const leadDealData = _generateLeadDealData({
    relatedEntityData: args.relatedEntityData,
  });

  args.dispatch(
    args.reducer({
      id: args.record.id,
      recordText: args.record.recordText,
      completionStatus: args.record.completionStatus!,
      executionTime: args.record.executionTime!,
      leadData: leadDealData.leadData,
      dealData: leadDealData.dealData,
    })
  );
};
