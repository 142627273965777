import camelize from "../../../utils/camelize";
import snakeize from "../../../utils/snakeize";
import { axiosInstance } from "../../../api/httpCommon";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import {
  UpdateUserAvatarResponseType,
  UpdateUserAvatarType,
  UpdateUserPasswordType,
  UpdateUserType,
  UserUsernameType,
  UserWithTasksType,
} from "../types/userTypes";

const getUsernamesList = async (): Promise<string[]> => {
  const response = await axiosInstance.get("user/get-usernames");

  const camelizedData = camelize(response.data);

  return camelizedData;
};

const getUserData = async (): Promise<UserWithTasksType> => {
  const response = await axiosInstance.get("user/get-one");

  const camelizedUserData = camelize(response.data);

  return camelizedUserData;
};

const updateUserAvatar = async (
  args: UpdateUserAvatarType
): Promise<UpdateUserAvatarResponseType> => {
  const response = await axiosInstance.patch(
    `user/update-avatar/${args.username}`,
    {
      avatar: args.avatar,
    }
  );

  return response.data;
};

const updateUserData = async (
  args: UpdateUserType
): Promise<CreateUpdateDeleteReturnOneValueType<string>> => {
  // if (!updateUserData.avatar?.startsWith("data:image")) {
  //   updateUserData.avatar = undefined;
  // }
  const updateUserDataWithoutUsername: any = { ...args };
  delete updateUserDataWithoutUsername.username;

  const snakeizedData = snakeize(updateUserDataWithoutUsername);

  const response = await axiosInstance.patch(
    `user/update-data/${args.username}`,
    snakeizedData
  );

  return response.data;
};

const updateUserPassword = async (
  args: UpdateUserPasswordType
): Promise<CreateUpdateDeleteReturnOneValueType<string>> => {
  const snakeizedData = snakeize(args);

  delete snakeizedData.username;

  const response = await axiosInstance.patch(
    `user/update-password/${args.username}`,
    snakeizedData
  );

  return response.data;
};

const userRequests = {
  getUsernamesList,
  getUserData,

  updateUserAvatar,
  updateUserData,
  updateUserPassword,
};

export { userRequests };
