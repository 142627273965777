import { LeadPreviewType } from "../../../lead/types/leadTypes";
import { LeadDataSSUType } from "../../types/leadTypes/leadDataTypes";
import { leadDataSSUTypePredicate } from "../../types/leadTypes/predicates";
import { RoutersArgsType, UpdateTypeEnum } from "../../types/types";
import { deleteLeadSystemUpdateHandler } from "./leadDataRouteHandlers/delete";
import { updateLeadSystemUpdateHandler } from "./leadDataRouteHandlers/update";

export const routeLeadUpdates = (args: RoutersArgsType) => {
  const state = args.getState();
  if (!leadDataSSUTypePredicate(args.eventData.ssuData)) {
    return;
  }
  if (state.lead.lead?.id !== args.eventData.ssuData.id) {
    return;
  }
  const ssuData = args.eventData.ssuData as LeadDataSSUType;

  switch (args.eventData.eventType) {
    case UpdateTypeEnum.create:
      // if (state.lead.leadListData.leadsList) {
      //   createLeadSystemUpdateHandler({
      //     // state: state,
      //     dispatch: args.dispatch,
      //     ssuData: args.eventData.ssuData as LeadDataSSUType,
      //   });
      // }
      break;

    case UpdateTypeEnum.update:
      updateLeadSystemUpdateHandler({
        state: state,
        dispatch: args.dispatch,
        ssuData: args.eventData.ssuData as LeadDataSSUType,
      });

      break;

    case UpdateTypeEnum.delete:
      deleteLeadSystemUpdateHandler({
        state: state,
        dispatch: args.dispatch,
        ssuData: args.eventData.ssuData as LeadPreviewType,
      });

      break;
    default:
      const _exhaustiveCheck: never = args.eventData.eventType;
      throw new Error("Invalid event type");
  }
};
