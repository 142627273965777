export interface CommonTheme {
  mediaMaxWidth: string;
  mediaMaxWidth834: string;
  mediaMaxWidth320: string;

  widthFor1440: string;
  widthFor834: string;
  widthFor320: string;

  colorDarkTransparent: string;
  colorLightTransparent: string;

  fontWeightThin: number;
  fontWeightMedium: number;
  fontWeightSemiBold: number;
  fontWeightBold: number;

  fontSize12: string;
  fontSize14: string;
  fontSize16: string;
  fontSize18: string;
  fontSize20: string;
  fontSize22: string;
  fontSize27: string;
  fontSize32: string;
  fontSize36: string;
  fontSize40: string;

  borderRadius: string;
  border: string;

  transitionStyle: string;
}

export enum ThemeColorsNamesEnum {
  colorDarkTransparent = "colorDarkTransparent",
  colorLightTransparent = "colorLightTransparent",

  colorPrimaryBackground = "colorPrimaryBackground",
  colorPrimaryText = "colorPrimaryText",
  colorSecondaryBackground = "colorSecondaryBackground",
  colorGreen = "colorGreen",
  colorRed = "colorRed",
  colorYellow = "colorYellow",
  colorPurple = "colorPurple",
  colorNone = "none",
  colorWhite = "colorWhite",
}
