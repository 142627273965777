import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../store";
import { leadPersonInChargeRequests } from "../../requests/leadPersonInChargeRequests";
import { showNotification } from "../../../notification/store/slice";
import { UpdateLeadPersonsInChargeDataAndLeadId } from "../../types/leadPersonInChargeTypes";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";

export const updateLeadPersonsInChargeListAsyncThunk = createAsyncThunk<
  string[],
  UpdateLeadPersonsInChargeDataAndLeadId,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "leadPersonsInCharge/updateLeadPersonsInChargeList",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await leadPersonInChargeRequests.updateLeadPersonsInChargeList(args);

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Список ответственных лида обновлён.",
        })
      );

      return response;
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText:
              "Лид был удалён другим пользователем. Пожалуйста сохраните необходимую информацию и покиньте страницу",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);
