import { UnknownObjectType } from "../commonTypes/unknownObjectType";

export const updateObjectDataByNewDataContainsNullAndUndefined = <
  T extends UnknownObjectType
>(args: {
  data: T;
  newUpdateData: any;
}): T => {
  /*
    MUTATES OBJECT.
    DOES NOT WORK WITH NULL AND UNDEFINED FIELDS!!!
    
    data: object for update;
    newUpdateData: object that APPARENTLY contains the same
      fields, or part of the same fields that data contains.
      If newUpdateData contains some additional fields, they
      will be ignored.

    Returns mutated object.
  */

  (Object.keys(args.newUpdateData) as Array<keyof Partial<T>>).forEach(
    (key) => {
      if (
        args.newUpdateData[key] === null ||
        args.newUpdateData[key] === undefined
      ) {
        return;
      }

      if (args.data[key] === undefined) {
        return;
      }

      if (args.data[key] === args.newUpdateData[key]) {
        return;
      }

      if (
        Object.prototype.toString.call(args.newUpdateData[key]) ===
        "[object object]"
      ) {
        updateObjectDataByNewDataContainsNullAndUndefined({
          data: args.data[key],
          newUpdateData: args.newUpdateData[key],
        });
        return;
      }

      args.data[key] = args.newUpdateData[key];
    }
  );

  return args.data;
};
// import { UnknownObjectType } from "../commonTypes/unknownObjectType";

// export const updateObjectDataByNewDataContainsNullAndUndefined = <
//   T extends UnknownObjectType
// >(args: {
//   data: T;
//   newUpdateData: any;
//   // newUpdateData: Partial<T>;
// }): T => {
//   /*
//     Copy provided object and updates it.
//     Returs updated copy of object.

//     DOES NOT MUTATE OBJECT.

//     data: object for update;
//     newUpdateData: object that APPARENTLY contains the same
//       fields, or part of the same fields that data contains.
//       If newUpdateData contains some additional fields, they
//       will be ignored.

//     Returns object with DEEP copy.

//     !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//     ALL RETURNED ELEMENTS ARE DEEP COPIES OF ORIGINAL DATA.
//     DO NOT USE THEM TO UPDATE ORIGINAL DATA.
//     COPIES GOT BY JSON STRINGIFY | JSON PARCE FUNCTIONS, SO
//     USE THIS FUNCTION ONLY FOR OBJECTS CONTAINS PRIMITEVE TYPES
//     LIKE STRINGS, NUMBERS OR BOOLEANS.

//     BE CAREFULL WITH OBJECTS CONTAINING MORE COMPLEX TYPES LIKE DATE.
//   */
//   const deepCopyofObjectForUpdate: T = JSON.parse(JSON.stringify(args.data));

//   (Object.keys(args.newUpdateData) as Array<keyof Partial<T>>).forEach(
//     (key) => {
//       if (
//         args.newUpdateData[key] === null ||
//         args.newUpdateData[key] === undefined
//       ) {
//         return;
//       }

//       if (args.data[key] === undefined) {
//         return;
//       }

//       if (
//         Object.prototype.toString.call(args.newUpdateData[key]) ===
//         "[object object]"
//       ) {
//         updateObjectDataByNewDataContainsNullAndUndefined({
//           data: args.data[key],
//           newUpdateData: args.newUpdateData[key],
//         });
//         return;
//       }
//       deepCopyofObjectForUpdate[key] = args.newUpdateData[key];
//       // Type 'T' is generic and can only be indexed for reading.ts(2862) ????
//     }
//   );

//   return deepCopyofObjectForUpdate;
// };
