import { PayloadAction } from "@reduxjs/toolkit";
import { FunnelInitialStateType } from "../../types/initialStateTypes";
import {
  FunnelType,
  FunnelWithRelatedFunnelStepsWithRelatedEntitiesType,
} from "../../types/funnelTypes";

const getFunnelsListFulfilled = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelType[]>
) => {
  state.funnelsList = action.payload;
};

const getFunnelStepsWithRelatedLeadsFulfilled = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelWithRelatedFunnelStepsWithRelatedEntitiesType>
) => {
  state.funnelWithFunnelStepsWithRelatedEntities = action.payload;
};

const editFunnelNameFulfilled = (
  state: FunnelInitialStateType,
  action: PayloadAction<string>
) => {
  try {
    if (state.funnelWithFunnelStepsWithoutRelatedEntities === undefined) {
      throw new Error(
        "Ошибка. Воронки не существует, пожалуйста, свяжитесь с поддержкой."
      );
    }
    state.funnelWithFunnelStepsWithoutRelatedEntities.funnelName =
      action.payload;
  } catch (error: any) {
    console.log(error);
  }
};

// const getFunnelStepsWithRelatedLeadsRejected = (
//   state: FunnelInitialStateType,
//   action: PayloadAction<Error | AxiosError<unknown, any> | undefined, string, { arg: number; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } & ({ rejectedWithValue: true; } | ({ ...; } & {})), SerializedError>
// ) => {
//   console.log(action.payload.message);
// };

const deleteFunnelFulfilled = (
  state: FunnelInitialStateType,
  action: PayloadAction<undefined>
) => {
  state.funnelWithFunnelStepsWithoutRelatedEntities = action.payload;
};

export const funnelExtraReducers = {
  getFunnelsListFulfilled,
  getFunnelStepsWithRelatedLeadsFulfilled,
  editFunnelNameFulfilled,
  deleteFunnelFulfilled,
};
