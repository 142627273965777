import { baseThemeDark } from "../../../theme/baseThemeDark";
import { ThemeColorsNamesEnum } from "../../../theme/types/types";

export enum NotificationTypeEnum {
  notification = "notification",
  warning = "warning",
  error = "error",
  success = "success",
}

export enum NotificationTitleEnum {
  notification = "Уведомление",
  warning = "Предупреждение",
  error = "Ошибка",
  success = "Успех",
}

export enum NotificationColorEnum {
  notification = ThemeColorsNamesEnum.colorSecondaryBackground,
  warning = ThemeColorsNamesEnum.colorYellow,
  error = ThemeColorsNamesEnum.colorRed,
  success = ThemeColorsNamesEnum.colorGreen,
}

export type NotificationType = {
  notificationTitle: NotificationTitleEnum;
  notificationBackgroundColor: NotificationColorEnum;
  notificationText: string;
  isVisible: boolean;
};

export type ShowNotificationData = {
  notificationType: NotificationTypeEnum;
  notificationText: string;
};
