import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { customFieldRequests } from "../requests/customFieldRequests";
import { AppDispatch } from "../../../store";
import { showNotification } from "../../notification/store/slice";
import { NotificationTypeEnum } from "../../notification/types/notificationTypes";
import { RejectWithValueErrorType } from "../../../commonTypes/rejectWithValueErrorType";
import {
  CreateCustomFieldType,
  CustomFieldType,
  DeleteCustomFieldType,
  UpdateCustomFieldRequestType,
  UpdateCustomFieldType,
} from "../types/customFieldTypes";

export const createCustomFieldAsyncThunk = createAsyncThunk<
  CustomFieldType,
  CreateCustomFieldType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "customField/createCustomField",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response = await customFieldRequests.createCustomField(args);
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Поле успешно создано",
        })
      );

      return {
        id: response.identifier,
        fieldName: args.createData.fieldName,
        fieldData: args.createData.fieldData,
      };
    } catch (error: any) {
      if (
        error.response.status === 409 &&
        error.response?.data?.error ===
          "Row with foreign key you trying to insert does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Связаный лид или сделка были удалены.",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const updateCustomFieldAsyncThunk = createAsyncThunk<
  UpdateCustomFieldType,
  UpdateCustomFieldRequestType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "customField/updateCustomField",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await customFieldRequests.updateCustomField(args);
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Данные поля успешно изменены",
        })
      );
      return {
        id: args.id,
        fieldData: args.fieldData,
      };
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Запрашиваемый объект был удалён",
          })
        );
      }
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const deleteCustomFieldAsyncThunk = createAsyncThunk<
  CreateUpdateDeleteReturnOneValueType<number>,
  DeleteCustomFieldType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "customField/deleteCustomField",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await customFieldRequests.deleteCustomField(args);

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Поле удалено",
        })
      );

      return { identifier: args.id };
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Запрашиваемый объект был удалён",
          })
        );
      }
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);
