import { addNewFunnelToFunnelsListSSU } from "../../../funnel/store/slice";
import { CreateUpdateFunnelsListUpdateDataType } from "../../types/funnelTypes/funnelsListTypes/funnelsListTypes";
import { FunnelsListSSUTypePredicate } from "../../types/funnelTypes/funnelsListTypes/predicates";
import { RoutersArgsType, UpdateTypeEnum } from "../../types/types";
import { deleteunnelSystemUpdateHandler } from "./funnelDataRouteHandlers/delete";
import { updateFunnelSystemUpdateHandler } from "./funnelDataRouteHandlers/update";

export const routeFunnelUpdates = (args: RoutersArgsType) => {
  if (!FunnelsListSSUTypePredicate(args.eventData.ssuData)) {
    return;
  }

  const state = args.getState();

  const ssuData = {
    funnelName: args.eventData.ssuData.funnelName,
    funnelId: args.eventData.ssuData.funnelId,
  };

  switch (args.eventData.eventType) {
    case UpdateTypeEnum.create:
      if (state.funnel.funnelsList) {
        args.dispatch(addNewFunnelToFunnelsListSSU(ssuData));
      }
      break;

    case UpdateTypeEnum.update:
      updateFunnelSystemUpdateHandler({
        dispatch: args.dispatch,
        state: state,
        eventData: args.eventData as CreateUpdateFunnelsListUpdateDataType,
      });
      break;

    case UpdateTypeEnum.delete:
      deleteunnelSystemUpdateHandler({
        state: state,
        dispatch: args.dispatch,
        funnelId: args.eventData.ssuData.funnelId,
      });
      break;

    default:
      const _exhaustiveCheck: never = args.eventData.eventType;
      throw new Error("Invalid event type");
  }
};

// switch (args.eventData.subEntityType) {
//   case FunnelSubEntityTypeEnum.funnelsList:
//     routeFunnelDataUpdates(args);
//     break;

//   case FunnelSubEntityTypeEnum.funnelWithFunnelStepsWithoutRelatedLeads:
//     routeFunnelStepsUpdates(args);
//     break;
// }
