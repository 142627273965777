import { axiosInstance } from "../../../api/httpCommon";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import camelize from "../../../utils/camelize";
import snakeize from "../../../utils/snakeize";
import { PositionType } from "../../user/types/userPositionTypes";

const getPositions = async (): Promise<PositionType[]> => {
  const respnose = await axiosInstance.get("position/all");

  const camelizedData = camelize(respnose.data);

  return camelizedData;
};

const cretePosition = async (args: {
  positionName: string;
}): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedData = snakeize(args);

  const response = await axiosInstance.post("position/create", snakeizedData);
  
  return response.data;
};

const updatePosition = async (args: {
  updatePositionData: { positionName: string };
  id: number;
}): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedData = snakeize(args.updatePositionData);

  const respnose = await axiosInstance.patch(
    `position/update/${args.id}`,
    snakeizedData
  );

  return respnose.data;
};

const deletePosition = async (
  args: CreateUpdateDeleteReturnOneValueType<number>
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const respnose = await axiosInstance.delete(
    `position/delete/${args.identifier}`
  );
  return respnose.data;
};

export const positionRequests = {
  getPositions,
  cretePosition,
  updatePosition,
  deletePosition,
};
