import { Socket } from "./utils/webSocketManager";

export class WebsocketConnector {
  private _attemptsCount: number = 0;
  private _maxAttempts: number;
  private _connectionURL: string;

  private _onMessageCallback: (innerArgs: { event: any }) => void;
  private _onOpenCallback: ((innerArgs: { event: any }) => void) | undefined;
  private _onCloseCallback: ((innerArgs: { event: any }) => void) | undefined;
  private _onErrorCallback: ((innerArgs: { event: any }) => void) | undefined;

  constructor(args: {
    maxAttempts: number;
    connectionURL: string;

    onMessageCallback: (innerArgs: { event: any }) => void;
    onOpenCallback?: (innerArgs: { event: any }) => void;
    onCloseCallback?: (innerArgs: { event: any }) => void;
    onErrorCallback?: (innerArgs: { event: any }) => void;
  }) {
    this._maxAttempts = args.maxAttempts;
    this._connectionURL = args.connectionURL;

    this._onMessageCallback = args.onMessageCallback;
    this._onOpenCallback = args.onOpenCallback;
    this._onCloseCallback = args.onCloseCallback;
    this._onErrorCallback = args.onErrorCallback;
  }

  _increateAttemptsCount() {
    this._attemptsCount++;
  }

  _resetAttemptsCount() {
    this._attemptsCount = 0;
  }

  _reconnect() {
    console.warn("WebSocket connection has been closed");

    if (this._attemptsCount >= this._maxAttempts) {
      console.error("Max attempts count has been reached");
      return;
    }

    console.log("Reconnecting...");

    this._increateAttemptsCount();

    setTimeout(() => {
      this.connectWebSocket();
    }, 3000 * this._attemptsCount);
  }

  _onOpenHandler(args: { event: any }) {
    console.log("WebSocket has been connected");
    if (this._onOpenCallback) {
      this._onOpenCallback({ event: args.event });
    }
  }

  _onMessageHandler(args: { event: any }) {
    this._onMessageCallback({ event: args.event });
  }

  _onCloseHandler(args: { event: any }) {
    if (this._onCloseCallback) {
      this._onCloseCallback({ event: args.event });
    }
    console.log("attempts count:", this._attemptsCount);

    this._reconnect();
  }

  _onErrorHandler(args: { event: any }) {
    if (this._onErrorCallback) {
      this._onErrorCallback({ event: args.event });
    }

    this._reconnect();
  }

  connectWebSocket() {
    try {
      const socket = new Socket();
      socket.connect(this._connectionURL);

      socket.on("open", (event) => this._onOpenHandler({ event }));
      socket.on("message", (event) => this._onMessageHandler({ event }));
      socket.on("close", (event) => this._onCloseHandler({ event }));
      socket.on("error", (event) => this._onErrorHandler({ event }));
    } catch (error) {
      console.error(error);
    }
  }
}
