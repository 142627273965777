export enum RelatedEntityTypeEnum {
  lead = "lead",
  deal = "deal",
}

export type TranslatorEnumType = {
  [key in keyof typeof RelatedEntityTypeEnum]: string;
};

export const relatedEntityRussianNameTranslator: TranslatorEnumType = {
  lead: "Лид",
  deal: "Сделка",
};

export type RelatedEntityDataType = {
  relatedEntityName: string;
  relatedEntityId: number;
  relatedEntityType: RelatedEntityTypeEnum;
  recordsContainerId: number;
};

export type RelatedEntityArgsType = {
  relatedEntity: RelatedEntityTypeEnum;
};
