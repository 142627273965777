import { PayloadAction } from "@reduxjs/toolkit";
import { DealInitialStateType } from "../../types/initialStateType";
import { DealType, UpdateDealType } from "../../types/dealTypes";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";

const getDealByIdExtraReducer = (
  state: DealInitialStateType,
  action: PayloadAction<DealType>
) => {
  state.deal = action.payload;
};

const updateDealExtraFulfilledExtraReducer = (
  state: DealInitialStateType,
  action: PayloadAction<UpdateDealType>
) => {
  if (!state.deal) {
    return;
  }
  updateObjectDataByNewDataContainsNullAndUndefined({
    data: state.deal,
    newUpdateData: action.payload,
  });
};

export const dealExtraReducers = {
  getDealByIdExtraReducer,
  updateDealExtraFulfilledExtraReducer,
};
