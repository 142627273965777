import { UnknownObjectType } from "../commonTypes/unknownObjectType";

export const setObjectFieldsUndefined = (args: {
  obj: UnknownObjectType;
  entitiesList: Partial<Array<string>>;
}): void => {
  /*
  MUTATES OBJECT!!
  Gets array of strigs, that are the names of object fields
  and object that's fields must be set to undefined.
  If object has such field it will be set up to undefined.
  */
  args.entitiesList.forEach((entity) => {
    if (entity !== undefined && args.obj.hasOwnProperty(entity)) {
      args.obj[entity] = undefined;
    }
  });
};
