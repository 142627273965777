import React from "react";

const Login = React.lazy(() =>
  import("../../apps/user/pages/login/Login").then((module) => ({
    default: module.Login,
  }))
);

const MainLayout = React.lazy(() =>
  import("../../layouts/mainLayout/MainLayout").then((module) => ({
    default: module.MainLayout,
  }))
);

const UserLayout = React.lazy(() =>
  import("../../apps/user/layouts/userLayout/UserLayout").then((module) => ({
    default: module.UserLayout,
  }))
);

const Tasks = React.lazy(() =>
  import("../../apps/user/pages/tasks/Tasks").then((module) => ({
    default: module.Tasks,
  }))
);

const User = React.lazy(() =>
  import("../../apps/user/pages/user/User").then((module) => ({
    default: module.User,
  }))
);

const LeadList = React.lazy(() =>
  import("../../apps/lead/pages/leadsList/LeadList").then((module) => ({
    default: module.LeadList,
  }))
);

const Lead = React.lazy(() =>
  import("../../apps/lead/pages/lead/Lead").then((module) => ({
    default: module.Lead,
  }))
);

const CreateLeadForm = React.lazy(() =>
  import("../../apps/lead/pages/createLeadForm/CreateLeadForm").then(
    (module) => ({ default: module.CreateLeadForm })
  )
);

const Deal = React.lazy(() =>
  import("../../apps/deal/pages/deal/Deal").then((module) => ({
    default: module.Deal,
  }))
);

const CreateDealForm = React.lazy(() =>
  import("../../apps/deal/pages/createDealForm/CreateDealForm").then(
    (module) => ({
      default: module.CreateDealForm,
    })
  )
);

const FunnelList = React.lazy(() =>
  import(
    "../../apps/funnel/pages/funnelsAndFunnelSteps/FunnelsAndFunnelSteps"
  ).then((module) => ({ default: module.FunnelList }))
);

const CreateFunnelPage = React.lazy(() =>
  import("../../apps/funnel/pages/createFunnelPage/CreateFunnelPage").then(
    (module) => ({ default: module.CreateFunnelPage })
  )
);

const EditFunnelPage = React.lazy(() =>
  import("../../apps/funnel/pages/editFunnelPage/EditFunnelPage").then(
    (module) => ({ default: module.EditFunnelPage })
  )
);

const AdminUsersList = React.lazy(() =>
  import("../../apps/user/pages/adminUsersList/AdminUsersList").then(
    (module) => ({
      default: module.AdminUsersList,
    })
  )
);
const AdminCreateUser = React.lazy(() =>
  import("../../apps/user/pages/adminCreateUser/AdminCreateUser").then(
    (module) => ({
      default: module.AdminCreateUser,
    })
  )
);

const PositionActions = React.lazy(() =>
  import("../../apps/position/pages/positionActions/PositionActions").then(
    (module) => ({ default: module.AdminPositionActions })
  )
);

const Page404 = React.lazy(() =>
  import("../404Page/Page404").then((module) => ({ default: module.Page404 }))
);

const ErrorPage = React.lazy(() =>
  import("../errorPage/ErrorPage").then((module) => ({
    default: module.ErrorPage,
  }))
);

export const reactLazyComponents = {
  Login,
  MainLayout,
  Tasks,
  User,
  AdminUsersList,
  AdminCreateUser,
  LeadList,
  Lead,
  Deal,
  CreateDealForm,
  FunnelList,
  CreateLeadForm,
  CreateFunnelPage,
  EditFunnelPage,
  PositionActions,
  Page404,
  ErrorPage,
};
