const isDate = (obj: any): boolean => {
  return Object.prototype.toString.call(obj) === "[object Date]";
};

const isRegex = (obj: any): boolean => {
  return Object.prototype.toString.call(obj) === "[object RegExp]";
};

function snakeize(obj: any): any {
  if (!obj || typeof obj !== "object") return obj;
  if (isDate(obj) || isRegex(obj)) return obj;
  if (Array.isArray(obj)) return obj.map(snakeize);
  return Object.keys(obj).reduce(function (acc: any, key: string) {
    const camel =
      key[0].toLowerCase() +
      key.slice(1).replace(/([A-Z]+)/g, function (m, x) {
        return "_" + x.toLowerCase();
      });
    acc[camel] = snakeize(obj[key]);
    return acc;
  }, {});
}


export default snakeize