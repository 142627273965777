import { PayloadAction } from "@reduxjs/toolkit";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import { RecordInitialStateType } from "../../types/initialStateType";
import { deleteListItemUsingForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";

const deleteUpdateRecordRejectedExtraReducer = (
  state: RecordInitialStateType,
  action: PayloadAction<RejectWithValueErrorType | undefined>
) => {
  if (state === undefined) {
    throw new Error("Record data does not exist");
  }
  if (!state.records || !state.records.length) {
    return;
  }
  if (
    action.payload &&
    action.payload.entityId &&
    action.payload.status === 404
  ) {
    const updatedContactsList = deleteListItemUsingForAndSpliceMethods({
      filterParam: "id",
      filterParamValue: action.payload.entityId,
      list: state.records,
    });

    if (updatedContactsList?.updatedArray) {
      state.records = updatedContactsList.updatedArray;
    }
  }
};

export const recordRejectedExtraReducers = {
  deleteUpdateRecordRejectedExtraReducer,
};
