import _history from "../../../../../const/history";
import { AppDispatch, RootState } from "../../../../../store";
import {
  deleteFunnelStepWithoutRelatedLeadsFromFunnelStepsArraySSU,
  deleteFunnelStepWithRelatedLeadsFromFunnelStepsArraySSU,
} from "../../../../funnel/store/slice";
import {
  updateLeadFunnelStepSSU,
  updateLeadItemFunnelAndFunnelStepNamesSSU,
} from "../../../../lead/store/slice";
import { FunnelOrFunnelStepHasBeedDeletedType } from "../../../../lead/types/leadFunnelTypes";
import { CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType } from "../../../types/funnelTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes";

export const deleteFunnelStepWithoutRelatedLeadSystemUpdateHandler = (args: {
  state: RootState;
  dispatch: AppDispatch;
  eventData: CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType;
}) => {
  if (
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities &&
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities.funnelId ===
      args.eventData.ssuData.funnelId
  ) {
    // FUNNELS' PAGE WITH FUNNEL STEPS WITH RELATED LEADS
    args.dispatch(
      deleteFunnelStepWithRelatedLeadsFromFunnelStepsArraySSU({
        identifier: args.eventData.ssuData.funnelStepUuid,
      })
    );
  } else if (
    args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities &&
    args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities.funnelId ===
      args.eventData.ssuData.funnelId
  ) {
    // UPDATE FUNNEL PAGE. STEPS WITHOUT LEADS
    if (!args.eventData.ssuData.funnelStepsList) {
      return;
    }
    args.dispatch(
      deleteFunnelStepWithoutRelatedLeadsFromFunnelStepsArraySSU(
        args.eventData.ssuData.funnelStepsList
      )
    );
    // UPDATE LEAD PAGE
  } else if (
    args.state.lead.lead?.funnelStep?.funnelStepUuid ===
    args.eventData.ssuData.funnelStepUuid
  ) {
    args.dispatch(updateLeadFunnelStepSSU(null));
  } else if (args.state.lead.leadListData?.leadsList) {
    const deletedSunnelStepData: FunnelOrFunnelStepHasBeedDeletedType = {
      funnelStepUuid:
        args.eventData.ssuData.funnelStepUuid,
      nullValue: null,
    };
    args.dispatch(
      updateLeadItemFunnelAndFunnelStepNamesSSU(deletedSunnelStepData)
    );
  }
};
