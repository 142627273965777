import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { userExtraReducers } from "./extraReducers/userExtraReducers";
import { userTasksReducers } from "./reducers/userTasksReducers";
import { userAdminExtraReducers } from "./extraReducers/userAdminFulfilledExtraReducers";
import {
  createUserAsyncThunk,
  deleteUserAdminAsyncThunk,
  getUsersListPreviewsAndPositionsListAsyncThunk,
  updateUserDataByAdminAsyncThunk,
} from "./asyncThunks/userAdminAsyncThunks";
import {
  getUserDataAsyncThunk,
  loginAsyncThunk,
  updateUserAvatarAsyncThunk,
  updateUserDataAsyncThunk,
} from "./asyncThunks/userAsyncThunks";
import { userRejectedExtraReducers } from "./extraReducers/userAdminRejectedExtraReducers";

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    createTask: userTasksReducers.createTaskReducer,
    updateTask: userTasksReducers.updateTaskReducer,
    deleteTask: userTasksReducers.deleteTaskReducer,

    // setUserTasksUndefined: userTasksReducers.setUserTasksUndefined,
  },
  extraReducers: (builder) => {
    builder.addCase(
      loginAsyncThunk.fulfilled,
      userExtraReducers.loginFulfilledExtraReducer
    );
    builder.addCase(
      getUserDataAsyncThunk.fulfilled,
      userExtraReducers.getUserDataFulfilledExtraReducer
    );

    builder.addCase(
      updateUserAvatarAsyncThunk.fulfilled,
      userExtraReducers.updateUserAvatarExtraReducer
    );
    builder.addCase(
      updateUserDataAsyncThunk.fulfilled,
      userExtraReducers.updateUserDataFulfilledExtraReducer
    );
    builder.addCase(updateUserDataAsyncThunk.rejected, (state, action) => {
      console.error(action.error);
    });

    builder.addCase(
      getUsersListPreviewsAndPositionsListAsyncThunk.fulfilled,
      userAdminExtraReducers.getUsersPreviewsAndPositionsListExtraReducer
    );
    builder.addCase(
      createUserAsyncThunk.fulfilled,
      userAdminExtraReducers.createUserFulfilledExtraReducer
    );
    builder.addCase(
      updateUserDataByAdminAsyncThunk.fulfilled,
      userAdminExtraReducers.updateUserPreviewAtUsersPreviewsList
    );
    builder.addCase(
      updateUserDataByAdminAsyncThunk.rejected,
      userRejectedExtraReducers.deleteUpdateUserRejectedExtraReducer
    );
    builder.addCase(
      deleteUserAdminAsyncThunk.fulfilled,
      userAdminExtraReducers.deleteUserFulfilledExtraReducer
    );
    builder.addCase(
      deleteUserAdminAsyncThunk.rejected,
      userRejectedExtraReducers.deleteUpdateUserRejectedExtraReducer
    );
  },
});

export const { createTask, updateTask, deleteTask } = userSlice.actions;

export default userSlice.reducer;
