import _history from "../../../../const/history";
import { RoutersArgsType } from "../../types/types";
import { contactDealSSUTypePredicate } from "../../types/contactsDealTypes/predicates";
import { updateRelatedEntityContactsListSSU } from "../../../contact/store/slice";

export const routeContactDealUpdates = (args: RoutersArgsType) => {
  if (!contactDealSSUTypePredicate(args.eventData.ssuData)) {
    return;
  }

  const state = args.getState();

  if (state.deal.deal?.id !== args.eventData.ssuData.dealId) {
    return;
  }

  args.dispatch(
    updateRelatedEntityContactsListSSU(args.eventData.ssuData.contactsList)
  );
};
