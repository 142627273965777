import { PayloadAction } from "@reduxjs/toolkit";
import { LeadInitialStateType } from "../../types/initialState";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";
import {
  LeadPreviewType,
  LeadsListAndPaginationDataType,
  LeadType,
  UpdateLeadDataType,
} from "../../types/leadTypes";

export const getLeadListExtraReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<LeadsListAndPaginationDataType>
) => {
  state.leadListData.leadsList = action.payload.leadsList;
  state.leadListData.paginationData = action.payload.paginationData;
};

export const getLeadExtraReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<LeadType>
) => {
  state.lead = action.payload;
};

export const updateLeadExtraReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<UpdateLeadDataType>
) => {
  if (!state.lead) {
    throw new Error("Lead has not bean loaded");
  }

  updateObjectDataByNewDataContainsNullAndUndefined({
    data: state.lead,
    newUpdateData: action.payload,
  });
};

export const leadExtraReducers = {
  getLeadListExtraReducer,
  getLeadExtraReducer,
  updateLeadExtraReducer,
};
