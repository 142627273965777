import { PayloadAction } from "@reduxjs/toolkit";
import { FunnelInitialStateType } from "../../types/initialStateTypes";
import { deleteListItemUsingForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import {
  FunnelStepUuidAndNameType,
  FunnelStepWithoutRelatedEntitiesType,
  FunnelStepWithRelatedEntitiesType,
} from "../../types/funnelStepTypes";
import { SimplifiedEntityType } from "../../types/funnelLeadTypes";
import { RelatedEntityTypeEnum } from "../../../../commonTypes/relatedEntityTypes";
import { LeadFunnelStepWithRelatedFunnelType } from "../../../lead/types/leadFunnelTypes";
import { UUID } from "crypto";

const removeDeletedEntityFromFunnelStepWithRelatedEntitiesSSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<{
    entityId: number;
    funnelStepUuid: UUID;
    entityType: RelatedEntityTypeEnum;
  }>
) => {
  /*
   All reducers with SSU suffix are used for websocket notification system.
   DO NOT! use this reducer at components layer.

   Removes deleted lead from funnel step with related leads
   */
  if (!state.funnelWithFunnelStepsWithRelatedEntities) {
    throw new Error(
      "Funnel and funnel steps with related leads are undefined.\
       Can't update it. Reducer has been called at the wrond place."
    );
  }

  const updatedFunnelStep = findElementInListUsingBuiltInFindMethod({
    list: state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps,
    lookForParam: "id",
    lookForParamValue: action.payload.funnelStepUuid,
  });
  if (!updatedFunnelStep) {
    return;
  }
  const updatedFunnelStepRelatedEntity =
    action.payload.entityType === RelatedEntityTypeEnum.lead
      ? "leads"
      : "deals";

  const deletedElementAndUpdatedArray = deleteListItemUsingForAndSpliceMethods({
    list: updatedFunnelStep[updatedFunnelStepRelatedEntity],
    filterParam: "id",
    filterParamValue: action.payload.entityId,
  });

  if (!deletedElementAndUpdatedArray) {
    return;
  }
  updatedFunnelStep[updatedFunnelStepRelatedEntity] =
    deletedElementAndUpdatedArray.updatedArray;
};

// //////////////////////////////////////////////////////////////////////////
const updateFunnelStepWithRealatedEntitiesNameAndFunnelStepSSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<{
    id: number;
    name: string;
    funnelStep: LeadFunnelStepWithRelatedFunnelType | null;
    relatedEntity: RelatedEntityTypeEnum;
  }>
) => {
  /*
   All reducers with SSU suffix are used for websocket notification system.
   DO NOT! use this reducer at components layer.

   Updates funnel step's name if it has been updated.
   */
  const relatedEntityKey =
    action.payload.relatedEntity === RelatedEntityTypeEnum.lead
      ? "leads"
      : "deals";

  const commonUpdateData = action.payload;
  if (!state.funnelWithFunnelStepsWithRelatedEntities) {
    return;
  }

  const funnelStepsWithRelatedEntities =
    state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps;

  let previosFunnelStep: FunnelStepWithRelatedEntitiesType | null = null;
  let entity: SimplifiedEntityType | null = null;

  funnelStepsIteration: for (
    let i = 0;
    i < funnelStepsWithRelatedEntities.length;
    i++
  ) {
    previosFunnelStep = funnelStepsWithRelatedEntities[i];

    for (let m = 0; m < previosFunnelStep.leads.length; m++) {
      if (previosFunnelStep[relatedEntityKey][m].id === commonUpdateData.id) {
        entity = previosFunnelStep[relatedEntityKey][m];
        break funnelStepsIteration;
      }
    }
    previosFunnelStep = null;
  }
  if (entity && previosFunnelStep) {
    // Lead exists at current funnel
    if (entity.name !== commonUpdateData.name) {
      // Update lead's company name if it has been changed

      entity.name = commonUpdateData.name;
    }

    if (!commonUpdateData.funnelStep) {
      return;
    }

    if (
      previosFunnelStep.funnelStepUuid !==
      commonUpdateData.funnelStep.funnelStepUuid
    ) {
      /*
         Lead's funnel step's id has been changed.
         funnel step's id is always unique because
         it is databse id primary key. So if it id
         has been changed, it could be asserted that
         lead's related funnel has been changed too. 
       */
      const deletedElementAndUpdatedArray =
        deleteListItemUsingForAndSpliceMethods({
          list: previosFunnelStep[relatedEntityKey],
          filterParam: "id",
          filterParamValue: action.payload.id,
        });

      if (
        !deletedElementAndUpdatedArray ||
        !deletedElementAndUpdatedArray.deletedElement
      ) {
        return;
      }
      // Update previos funnel step leads array/
      previosFunnelStep[relatedEntityKey] =
        deletedElementAndUpdatedArray.updatedArray;

      if (
        state.funnelWithFunnelStepsWithRelatedEntities.funnelId !==
        commonUpdateData.funnelStep.funnel.funnelId
      ) {
        /* 
          Check has leads funnel been changed. If yes just need
          remove element from current funnel step, what already
          has been done.
          */
        return;
      }

      /*
        Leed has been moved to another step of current funnel.
        But funnel has not been changed.
        Looking for the new funnel step for the lead.
       */
      const newEntityFunnelStep = findElementInListUsingBuiltInFindMethod({
        list: state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps,
        lookForParam: "id",
        lookForParamValue: commonUpdateData.funnelStep.funnelStepUuid,
      });

      if (!newEntityFunnelStep) {
        return;
      }
      newEntityFunnelStep.leads.push(
        deletedElementAndUpdatedArray.deletedElement
      );
      return;
    }
  }

  if (!previosFunnelStep && !entity && commonUpdateData.funnelStep) {
    /*
        Lead has not been related with any funnel step of any funnel
        or has been realted with another funnel. This case just need
        to add lead to its realted funnel step lead list.
       */
    const newEntityFunnelStep = findElementInListUsingBuiltInFindMethod({
      list: state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps,
      lookForParam: "funnelStepUuid",
      lookForParamValue: commonUpdateData.funnelStep.funnelStepUuid,
    });
    if (!newEntityFunnelStep) {
      return;
    }
    newEntityFunnelStep[relatedEntityKey].push({
      id: commonUpdateData.id,
      name: commonUpdateData.name,
      recordsContainerId:1,
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1nkjkjkjkjkjkj
    });
    return;
  }
};

// //////////////////////////////////////////////////////////////////////////

const addNewFunnelStepWithRelatedEntitiesToFunnelStepsArraySSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelStepWithRelatedEntitiesType>
) => {
  /*
   All reducers with SSU suffix are used for websocket notification system(SSU).
   DO NOT! use this reducer at components layer.

   Adds new empty funnel step to the end of funnel steps with related
   leads list of the funnel.
   */

  if (!state.funnelWithFunnelStepsWithRelatedEntities) {
    return;
  }
  state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps.push(
    action.payload
  );
};

const updateFunnelStepWithRelatedEntitiesNameSSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelStepUuidAndNameType>
) => {
  /*
   All reducers with SSU suffix are used for websocket notification system(SSU).
   DO NOT! use this reducer at components layer.

   Updates name of funnel step at funnel step with related leads page
   */

  if (!state.funnelWithFunnelStepsWithRelatedEntities) {
    return;
  }
  const updatedFunnelStep = findElementInListUsingBuiltInFindMethod({
    list: state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps,
    lookForParam: "funnelStepUuid",
    lookForParamValue: action.payload.funnelStepUuid,
  });

  if (!updatedFunnelStep) {
    return;
  }
  updatedFunnelStep.funnelStepName = action.payload.funnelStepName;
};

const updateFunnelStepsOrderAtFunnelStepsWithRelatedEntitiesSSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<{
    updatedFunnelStepUuid: UUID;
    updatedFunnelStepsArray: FunnelStepWithoutRelatedEntitiesType[];
  }>
) => {
  if (!state.funnelWithFunnelStepsWithRelatedEntities) {
    return;
  }

  const updatedFunnelStepsArrayAndRemovedFunnelStep =
    deleteListItemUsingForAndSpliceMethods({
      list: state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps,
      filterParam: "funnelStepUuid",
      filterParamValue: action.payload.updatedFunnelStepUuid,
    });

  if (!updatedFunnelStepsArrayAndRemovedFunnelStep) {
    return;
  }
  if (!updatedFunnelStepsArrayAndRemovedFunnelStep.deletedElement) {
    console.error("funnel step does not exist at current funnel steps list");
    return;
  }

  const funnelStepNewPositionIndex =
    action.payload.updatedFunnelStepsArray.findIndex(
      (funnelStep) =>
        funnelStep.funnelStepUuid === action.payload.updatedFunnelStepUuid
    );

  if (funnelStepNewPositionIndex === -1) {
    console.error("index has not been found at updated funnel steps list");
    return;
  }

  updatedFunnelStepsArrayAndRemovedFunnelStep.updatedArray.splice(
    funnelStepNewPositionIndex,
    0,
    updatedFunnelStepsArrayAndRemovedFunnelStep.deletedElement
  );

  state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps =
    updatedFunnelStepsArrayAndRemovedFunnelStep.updatedArray;
};

const deleteFunnelStepWithRelatedEntitiesFromFunnelStepsArraySSUReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<UUID>>
) => {
  /*
   All reducers with SSU suffix are used for websocket notification system(SSU).
   DO NOT! use this reducer at components layer.

   Removes funnel step from the list of funnel steps with related
   leads list of the funnel.
   */

  if (!state.funnelWithFunnelStepsWithRelatedEntities) {
    return;
  }

  const deletedFunnelStepAndUpdatedFunnelStepsArray =
    deleteListItemUsingForAndSpliceMethods({
      list: state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps,
      filterParam: "id",
      filterParamValue: action.payload.identifier,
    });
  if (!deletedFunnelStepAndUpdatedFunnelStepsArray) {
    return;
  }

  state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps =
    deletedFunnelStepAndUpdatedFunnelStepsArray.updatedArray;
};

export const funnelStepReducers = {
  removeDeletedEntityFromFunnelStepWithRelatedEntitiesSSUReducer,
  updateFunnelStepWithRealatedEntitiesNameAndFunnelStepSSUReducer,

  addNewFunnelStepWithRelatedEntitiesToFunnelStepsArraySSUReducer,
  updateFunnelStepWithRelatedEntitiesNameSSUReducer,
  updateFunnelStepsOrderAtFunnelStepsWithRelatedEntitiesSSUReducer,
  deleteFunnelStepWithRelatedEntitiesFromFunnelStepsArraySSUReducer,
};

// const updateFunnelStepRelatedEntityData = (
//   state: FunnelInitialStateType,
//   action: PayloadAction<{
//     id: number;
//     name: string;
//     funnelStep: LeadFunnelStepWithRelatedFunnelType | null;
//     relatedEntity: RelatedEntityTypeEnum;
//   }>
// ) => {
//   /*
//    All reducers with SSU suffix are used for websocket notification system.
//    DO NOT! use this reducer at components layer.

//    Updates funnel step's name if it has been updated.
//    */
//   const relatedEntityKey =
//     action.payload.relatedEntity === RelatedEntityTypeEnum.lead
//       ? "leads"
//       : "deals";

//   const commonUpdateData = action.payload;
//   if (!state.funnelWithFunnelStepsWithRelatedEntities) {
//     return;
//   }

//   const funnelStepsWithRelatedEntities =
//     state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps;

//   let previosFunnelStep: FunnelStepWithRelatedEntitiesType | null = null;
//   let entity: SimplifiedEntityType | null = null;

//   funnelStepsIteration: for (
//     let i = 0;
//     i < funnelStepsWithRelatedEntities.length;
//     i++
//   ) {
//     previosFunnelStep = funnelStepsWithRelatedEntities[i];

//     for (let m = 0; m < previosFunnelStep.leads.length; m++) {
//       if (previosFunnelStep[relatedEntityKey][m].id === commonUpdateData.id) {
//         entity = previosFunnelStep[relatedEntityKey][m];
//         break funnelStepsIteration;
//       }
//     }
//     previosFunnelStep = null;
//   }
//   if (entity && previosFunnelStep) {
//     // Lead exists at current funnel
//     if (entity.name !== commonUpdateData.name) {
//       // Update lead's company name if it has been changed

//       entity.name = commonUpdateData.name;
//     }

//     if (!commonUpdateData.funnelStep) {
//       return;
//     }

//     if (previosFunnelStep.id !== commonUpdateData.funnelStep.id) {
//       /*
//           Lead's funnel step's id has been changed.
//           funnel step's id is always unique because
//           it is databse id primary key. So if it id
//           has been changed, it could be asserted that
//           lead's related funnel has been changed too.
//         */
//       const deletedElementAndUpdatedArray =
//         deleteListItemUsingForAndSpliceMethods({
//           list: previosFunnelStep[relatedEntityKey],
//           filterParam: "id",
//           filterParamValue: action.payload.id,
//         });

//       if (
//         !deletedElementAndUpdatedArray ||
//         !deletedElementAndUpdatedArray.deletedElement
//       ) {
//         return;
//       }
//       // Update previos funnel step leads array/
//       previosFunnelStep[relatedEntityKey] =
//         deletedElementAndUpdatedArray.updatedArray;

//       if (
//         state.funnelWithFunnelStepsWithRelatedEntities.id !==
//         commonUpdateData.funnelStep.funnel.id
//       ) {
//         /*
//            Check has leads funnel been changed. If yes just need
//            remove element from current funnel step, what already
//            has been done.
//            */
//         return;
//       }

//       /*
//          Leed has been moved to another step of current funnel.
//          But funnel has not been changed.
//          Looking for the new funnel step for the lead.
//         */
//       const newEntityFunnelStep = findElementInListUsingBuiltInFindMethod({
//         list: state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps,
//         lookForParam: "id",
//         lookForParamValue: commonUpdateData.funnelStep.id,
//       });

//       if (!newEntityFunnelStep) {
//         return;
//       }
//       newEntityFunnelStep.leads.push(
//         deletedElementAndUpdatedArray.deletedElement
//       );
//       return;
//     }
//   }

//   if (!previosFunnelStep && !entity && commonUpdateData.funnelStep) {
//     /*
//          Lead has not been related with any funnel step of any funnel
//          or has been realted with another funnel. This case just need
//          to add lead to its realted funnel step lead list.
//         */
//     const newEntityFunnelStep = findElementInListUsingBuiltInFindMethod({
//       list: state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps,
//       lookForParam: "id",
//       lookForParamValue: commonUpdateData.funnelStep.id,
//     });
//     if (!newEntityFunnelStep) {
//       return;
//     }
//     newEntityFunnelStep[relatedEntityKey].push({
//       id: commonUpdateData.id,
//       name: commonUpdateData.name,
//     });
//     return;
//   }
// };
