import { UUID } from "crypto";
import { funnelStepRequests } from "../../requests/funnelStepRequests";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AppDispatch } from "../../../../store";
import { showNotification } from "../../../notification/store/slice";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import {
  FunnelType,
  FunnelWithRelatedFunnelStepsWithRelatedEntitiesType,
} from "../../types/funnelTypes";
import {
  EditSimplifiedEntityOfFunnelStepType,
  FunnelStepWithoutRelatedEntitiesType,
  FunnelStepUuidAndNameType,
  CreateFunnelStepType,
  UpdateFunnelStepsOrderType,
  EditSimplifiedEntityOfFunnelStepRequestType,
} from "../../types/funnelStepTypes";

export const getFunnelStepsWithRelatedLeadsAsyncThunk = createAsyncThunk<
  FunnelWithRelatedFunnelStepsWithRelatedEntitiesType,
  FunnelType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "funnel/getFunnelStepsWithRelatedLeads",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const funnelStepWithRelatedLeads =
        await funnelStepRequests.getFunnelStepsWithRelatedLeads(args.funnelId);

      return {
        funnelName: args.funnelName,
        funnelSteps: funnelStepWithRelatedLeads,
        funnelId: args.funnelId,
      };
    } catch (error: any) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText:
            "Произошла ошибка. Пожалуйста перезагрузите страницу или свяжитесь с администратором.",
        })
      );
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const createFunnelStepAsyncThunk = createAsyncThunk<
  FunnelStepWithoutRelatedEntitiesType[],
  CreateFunnelStepType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "funnel/createFunnelStepAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response = await funnelStepRequests.createFunnelStep(args);
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Шаг воронки успешно создан.",
        })
      );
      return response;
    } catch (error: any) {
      if (
        error.response.status === 409 &&
        error.response?.data?.error ===
          "Row with foreign key you trying to insert does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Связаная воронка была удалена.",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const changeEntityFunnelStepByDragAndDropAsyncThunk = createAsyncThunk<
  EditSimplifiedEntityOfFunnelStepType,
  EditSimplifiedEntityOfFunnelStepRequestType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "funnel/changeLeadsFunnelStepByDragAndDropAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const updateData = {
        recordsContainerId: args.currentEntityData.recordsContainerId,
        updatedEntityId: args.currentEntityData.id,
        updateData: { funnelStepUuid: args.targetFunnelStepUuid },
      };

      // args.currentEntityData.entityType === RelatedEntityTypeEnum.lead
      //   ? await leadRequests.updateLead(updateData)
      //   : await dealRequests.updateDeal(updateData);
      args.requestFunction(updateData);

      const returnData = {
        currentFunnelStepUuid: args.currentFunnelStepUuid,
        currentEntityData: args.currentEntityData,
        targetFunnelStepUuid: args.targetFunnelStepUuid,
      };

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Шаг воронки успешно изменен.",
        })
      );
      return returnData;
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const changeFunnelStepOrderByDragAndDropAsyncThunk = createAsyncThunk<
  FunnelStepWithoutRelatedEntitiesType[],
  UpdateFunnelStepsOrderType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "funnel/changeFunnelStepOrderByDragAndDropAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const requestData: UpdateFunnelStepsOrderType = {
        funnelId: args.funnelId,
        updateFunnelStepUuid: args.updateFunnelStepUuid,
        prevStepUuid: args.prevStepUuid,
        nextStepUuid: args.nextStepUuid,
      };
      const response = await funnelStepRequests.updateFunnelStepsOrder(
        requestData
      );

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Порядок шагов воронки изменён.",
        })
      );
      return response;
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText:
              "Этот шаг или воронка были удалёны другим пользователем. Страница автоматически перезагружена для получения актуальных данных",
          })
        );
      }
      if (error.response.status === 406) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Неверный порядок шагов воронки.",
          })
        );
      }
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const updateFunnelStepNameAsyncThunk = createAsyncThunk<
  FunnelStepUuidAndNameType,
  FunnelStepUuidAndNameType,
  { rejectValue: Error | AxiosError; dispatch: AppDispatch }
>(
  "/updateFunnelStepNameAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await funnelStepRequests.updateFunnelStepName({
        funnelStepUuid: args.funnelStepUuid,
        funnelStepName: args.funnelStepName,
      });

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Имя шага воронки изменено.",
        })
      );

      return args;
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText:
              "Этот шаг или воронка были удалёны другим пользователем. Страница автоматически перезагружена для получения актуальных данных",
          })
        );
      }

      return rejectWithValue(error);
    }
  }
);

export const deleteFunnelStepAsyncThunk = createAsyncThunk<
  FunnelStepWithoutRelatedEntitiesType[],
  CreateUpdateDeleteReturnOneValueType<UUID>,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "funnel/deleteFunnelStepAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response = await funnelStepRequests.deleteFunnelStep(args);

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Шаг воронки удалён.",
        })
      );

      return response;
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText:
              "Этот шаг или воронка были удалёны другим пользователем. Страница автоматически перезагружена для получения актуальных данных",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);
