import {
  TasksSortedByExecutionTimeType,
  UserTaskType,
} from "../../types/userTasksTypes";

export const selectTasksArrayForAddUpdateOrDeleteOperation = (args: {
  userTasks: TasksSortedByExecutionTimeType;
  executionTime: string;
}): UserTaskType[] | undefined => {
  const executionTime = new Date(args.executionTime);
  const currentDate = new Date();

  let taskArray: UserTaskType[] | undefined = undefined;

  if (
    args.executionTime.substring(0, 10) ===
    currentDate.toISOString().substring(0, 10)
  ) {
    taskArray = args.userTasks.todayTasks;
  } else if (executionTime < currentDate) {
    taskArray = args.userTasks.overduedTasks;
  } else if (executionTime > currentDate) {
    taskArray = args.userTasks.futureTasks;
  }
  return taskArray;
};
