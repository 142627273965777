import _history from "../../../../const/history";
import { UpdateTypeEnum, RoutersArgsType } from "../../types/types";
import { funnelWithFunnelStepsWithoutRelatedLeadsSSUTypePredicate } from "../../types/funnelTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes/predicates";
import { createFunnelStepWithoutRelatedLeadSystemUpdateHandler } from "./funnelStepRouteHandlers/create";
import { CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType } from "../../types/funnelTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes";
import { updateFunnelStepWithoutRelatedLeadSystemUpdateHandler } from "./funnelStepRouteHandlers/update";
import { deleteFunnelStepWithoutRelatedLeadSystemUpdateHandler } from "./funnelStepRouteHandlers/delete";

export const routeFunnelStepsUpdates = (args: RoutersArgsType) => {
  console.log(args);
  if (
    !funnelWithFunnelStepsWithoutRelatedLeadsSSUTypePredicate(
      args.eventData.ssuData
    )
  ) {
    return;
  }

  const state = args.getState();

  switch (args.eventData.eventType) {
    case UpdateTypeEnum.create:
      createFunnelStepWithoutRelatedLeadSystemUpdateHandler({
        dispatch: args.dispatch,
        state: state,
        eventData:
          args.eventData as CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType,
      });
      break;

    case UpdateTypeEnum.update:
      updateFunnelStepWithoutRelatedLeadSystemUpdateHandler({
        dispatch: args.dispatch,
        state: state,
        eventData:
          args.eventData as CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType,
      });
      break;

    case UpdateTypeEnum.delete:
      deleteFunnelStepWithoutRelatedLeadSystemUpdateHandler({
        dispatch: args.dispatch,
        state: state,
        eventData:
          args.eventData as CreateUpdateDeleteFunnelStepsWithoutRelatedLeadsUpdateDataType,
      });
      break;

    default:
      const _exhaustiveCheck: never = args.eventData.eventType;
      throw new Error("Invalid event type");
  }
};
