import { createSlice } from "@reduxjs/toolkit";
import { leadIinitialState } from "./initialState";
import { leadReducers } from "./reducers/leadReducers";
import { leadListReducers } from "./reducers/leadListReducers";
import { leadFunnelStepReducers } from "./reducers/funnelStepReducers";
import { getLeadListAsyncThunk } from "./asyncThunks/leadListAsyncThunks";
import { leadExtraReducers } from "./extraReducers/leadExtraReducers";
import {
  getLeadAsyncThunk,
  updateLeadAsyncThunk,
} from "./asyncThunks/leadAsyncThunks";
import { updateLeadPersonsInChargeListAsyncThunk } from "./asyncThunks/leadPersonsInChargeAsyncThunks";
import { leadPersonInChargeExtraReducers } from "./extraReducers/leadPersonsInChargeExtraReducers";

const leadSlice = createSlice({
  name: "lead",
  initialState: leadIinitialState,
  reducers: {
    setUndefinedWhenLeavingPage:
      leadReducers.setUndefinedWhenLeavingPageReducer,

    //SSU reducers
    // update lead data
    updateLeadSSU: leadExtraReducers.updateLeadExtraReducer,
    updateLeadFunnelStepSSU:
      leadFunnelStepReducers.updateLeadFunnelStepSSUReducer,
    addNewLeadToLeadListSSU: leadListReducers.addNewLeadToLeadListSSUReducer,
    // update lead persons in charge reducers
    updateLeadPersonsInChargeSSU:
      leadPersonInChargeExtraReducers.updateLeadPersonsInChargeListFulfilledExtraReducer,

    // update lead list data
    removeDeletedLeadFromLeadListSSU:
      leadListReducers.removeDeletedLeadFromLeadListSSUReducer,
    updateLeadItemFunnelAndFunnelStepNamesSSU:
      leadListReducers.updateLeadItemFunnelAndFunnelStepNamesSSUReducer,
    updateLeadListItemSSU: leadListReducers.updateLeadListItemSSUReducer,

    // update lead's deals list
    addNewDealToLeadDealsListSSU:
      leadReducers.addNewDealToLeadDealsListSSUReducer,
    updateDealAtLeadDealsPreviewsListSSU:
      leadReducers.updateDealAtLeadDealsPreviewsListSSUReducer,
    deleteDealFromLeadDealsListSSU:
      leadReducers.deleteDealFromLeadDealsListSSUReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getLeadListAsyncThunk.fulfilled,
      leadExtraReducers.getLeadListExtraReducer
    );

    builder.addCase(
      getLeadAsyncThunk.fulfilled,
      leadExtraReducers.getLeadExtraReducer
    );
    builder.addCase(getLeadAsyncThunk.rejected, (state, action) => {
      console.log(action.error.message);
    });
    builder.addCase(
      updateLeadAsyncThunk.fulfilled,
      leadExtraReducers.updateLeadExtraReducer
    );
    builder.addCase(updateLeadAsyncThunk.rejected, (state, action) => {
      console.log(action.error.message);
    });
    builder.addCase(
      updateLeadPersonsInChargeListAsyncThunk.fulfilled,
      leadPersonInChargeExtraReducers.updateLeadPersonsInChargeListFulfilledExtraReducer
    );
  },
});

export const {
  setUndefinedWhenLeavingPage,

  updateLeadSSU,
  updateLeadFunnelStepSSU,
  addNewLeadToLeadListSSU,

  updateLeadPersonsInChargeSSU,

  removeDeletedLeadFromLeadListSSU,
  updateLeadItemFunnelAndFunnelStepNamesSSU,
  updateLeadListItemSSU,

  addNewDealToLeadDealsListSSU,
  updateDealAtLeadDealsPreviewsListSSU,
  deleteDealFromLeadDealsListSSU,
} = leadSlice.actions;

export default leadSlice.reducer;
