import { createSlice } from "@reduxjs/toolkit";
import { recordIinitialState } from "./initialState";
import { recordExtraReducers } from "./extraReducers/fulfilledExtraReducers";
import { recordRejectedExtraReducers } from "./extraReducers/rejectedExtraReducers";
import { recordReducers } from "./reducers";
import {
  createRecordAsyncThunk,
  deleteRecordAsyncThunk,
  updateRecordAsyncThunk,
} from "./asyncThunks/asyncThunks";

const recordSlice = createSlice({
  name: "record",
  initialState: recordIinitialState,
  reducers: {
    setRecords: recordReducers.setRecordsReducer,
    setRecordsInitialStateUndefinedWhenLeavingLeadOrDealPage:
      recordReducers.setRecordsInitialStateUndefinedWhenLeavingLeadOrDealPageReducer,

    createRecordSSU: recordExtraReducers.createRecordExtraReducer,
    updateRecordSSU: recordExtraReducers.updateRecordExtraReducer,
    deleteRecordSSU: recordExtraReducers.deleteRecordExtraReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(
      createRecordAsyncThunk.fulfilled,
      recordExtraReducers.createRecordExtraReducer
    );

    builder.addCase(createRecordAsyncThunk.rejected, (state, action) => {
      console.log(action.error.message);
    });
    builder.addCase(
      updateRecordAsyncThunk.fulfilled,
      recordExtraReducers.updateRecordExtraReducer
    );
    builder.addCase(
      updateRecordAsyncThunk.rejected,
      recordRejectedExtraReducers.deleteUpdateRecordRejectedExtraReducer
    );
    builder.addCase(
      deleteRecordAsyncThunk.fulfilled,
      recordExtraReducers.deleteRecordExtraReducer
    );
    builder.addCase(
      deleteRecordAsyncThunk.rejected,
      recordRejectedExtraReducers.deleteUpdateRecordRejectedExtraReducer
    );
  },
});

export const {
  setRecords,
  setRecordsInitialStateUndefinedWhenLeavingLeadOrDealPage,

  createRecordSSU,
  updateRecordSSU,
  deleteRecordSSU,
} = recordSlice.actions;

export default recordSlice.reducer;
