import { updateDealPersonsInChargeSSU } from "../../../deal/store/slice";
import { dealPersonsInChargeSSUTypePredicate } from "../../types/dealPersonsInChargeTypes/predicates";
import { RoutersArgsType } from "../../types/types";

export const routeDealPersonsInChargeUpdates = (args: RoutersArgsType) => {
  const state = args.getState();

  if (
    !dealPersonsInChargeSSUTypePredicate(args.eventData.ssuData) ||
    !state.deal.deal
  ) {
    return;
  }

  if (state.deal.deal?.id !== args.eventData.ssuData.dealId) {
    return;
  }

  args.dispatch(
    updateDealPersonsInChargeSSU(args.eventData.ssuData.personsInChargeList)
  );
};
