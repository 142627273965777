import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../store";
import { showNotification } from "../../../notification/store/slice";
import { userAdminRequests } from "../../requests/userAdminRequests";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { CreateUserAsyncThunkType } from "../../types/userTypes";
import {
  UpdateUserDataAdminAsyncThunkRequestType,
  UserListPreviewsAndPositionsListAdminType,
} from "../../types/userAdminTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";

export const getUsersListPreviewsAndPositionsListAsyncThunk = createAsyncThunk<
  UserListPreviewsAndPositionsListAdminType,
  undefined,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "userAdmin/getUsersListPreviewsAndPositionsListAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await userAdminRequests.getUsersPreviewsAndPositionsList();

      return response;
    } catch (error: any) {
      if (
        error.response.status === 401 &&
        error.response?.data?.error === "User does not have admin rights"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Вы не можете просматривать эту страницу.",
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const createUserAsyncThunk = createAsyncThunk<
  CreateUserAsyncThunkType,
  CreateUserAsyncThunkType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "useradmin/createUserAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await userAdminRequests.createUser({
        isAdmin: args.isAdmin,
        name: args.name,
        password: args.password,
        positionId: args.position && args.position.id,
        username: args.username,
      });
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Пользователь создан",
        })
      );
      return args;
    } catch (error: any) {
      if (
        error.response.status === 401 &&
        error.response?.data?.error === "User does not have admin rights"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Вы не имеете прав для создания пользователя.",
          })
        );
      }
      
      if (error.response.status === 409) {

        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Пользователь с таким логином уже существует",
          })
        );
      }
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const updateUserDataByAdminAsyncThunk = createAsyncThunk<
  UpdateUserDataAdminAsyncThunkRequestType,
  UpdateUserDataAdminAsyncThunkRequestType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "userAdmin/updateUserDataByAdminAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await userAdminRequests.updateUserDataByAdmin({
        username: args.username,
        updateUserData: {
          isActive: args.isActive,
          isAdmin: args.isAdmin,
          positionId: args.position?.id,
        },
      });
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Данные успешно обновлены.",
        })
      );

      return args;
    } catch (error: any) {
      if (
        error.response.status === 401 &&
        error.response?.data?.error === "User does not have admin rights"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Вы не можете просматривать эту страницу.",
          })
        );
      }
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Пользователь был удалён",
          })
        );
      }
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
        entityId: args.username,
      });
    }
  }
);

export const deleteUserAdminAsyncThunk = createAsyncThunk<
  CreateUpdateDeleteReturnOneValueType<string>,
  CreateUpdateDeleteReturnOneValueType<string>,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "userAdmin/deleteUserAdminAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await userAdminRequests.deleteUser({
        username: args.identifier,
      });

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Пользователь успешно удалён.",
        })
      );

      return args;
    } catch (error: any) {
      if (
        error.response.status === 401 &&
        error.response?.data?.error === "User does not have admin rights"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Вы не можете просматривать эту страницу.",
          })
        );
      }
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Пользователь был удалён",
          })
        );
      }
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
        entityId: args.identifier,
      });
    }
  }
);
