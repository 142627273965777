import { PayloadAction } from "@reduxjs/toolkit";
import { ContactInitialStateType } from "../../types/initialStateType";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import { deleteListItemUsingForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";

const updateDeleteContactRejectedExtraReducer = (
  state: ContactInitialStateType,
  action: PayloadAction<RejectWithValueErrorType | undefined>
) => {

  if (!state.contacts) {
    throw new Error("Contacts has not bean loaded");
  }

  if (
    action.payload &&
    action.payload.entityId &&
    action.payload.status === 404
  ) {
    const updatedContactsList = deleteListItemUsingForAndSpliceMethods({
      filterParam: "id",
      filterParamValue: action.payload.entityId,
      list: state.contacts,
    });

    if (updatedContactsList?.updatedArray) {
      state.contacts = updatedContactsList.updatedArray;
    }
  }
};

export const contactRejectedExtraReducers = {
  updateDeleteContactRejectedExtraReducer,
};
