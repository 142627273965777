import _history from "../../../../const/history";
import { ContactSSUTypePredicate } from "../../types/contactTypes/predicates";
import { UpdateTypeEnum, RoutersArgsType } from "../../types/types";
import {
  createContactSSU,
  updateContactSSU,
  deleteContactSSU,
} from "../../../contact/store/slice";

export const routeContactsUpdates = (args: RoutersArgsType) => {
  if (!ContactSSUTypePredicate(args.eventData.ssuData)) {
    return;
  }
  
  const state = args.getState();
  if (
    args.eventData.ssuData.contactsContainerId !==
    state.contact.contactsContainerId
  ) {
    return;
  }
  const ssuData = {
    email: args.eventData.ssuData.email,
    id: args.eventData.ssuData.id,
    name: args.eventData.ssuData.name,
    phoneNumber: args.eventData.ssuData.phoneNumber,
    tgUsername: args.eventData.ssuData.tgUsername,
  };

  switch (args.eventData.eventType) {
    case UpdateTypeEnum.create:
      args.dispatch(createContactSSU(ssuData));
      break;

    case UpdateTypeEnum.update:
      
      args.dispatch(updateContactSSU(ssuData));
      break;

    case UpdateTypeEnum.delete:
      args.dispatch(
        deleteContactSSU({ identifier: args.eventData.ssuData.id })
      );
      break;

    default:
      const _exhaustiveCheck: never = args.eventData.eventType;
      throw new Error("Invalid event type");
  }
};
