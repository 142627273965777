import { SSUType } from "../types";
import { DealPersonsInChargeListType } from "./dealPersonsInChargeTypes";

export const dealPersonsInChargeSSUTypePredicate = (
  arg: SSUType
): arg is DealPersonsInChargeListType => {
  return (
    (arg as DealPersonsInChargeListType).personsInChargeList !== undefined &&
    (arg as DealPersonsInChargeListType).dealId !== undefined
  );
};
