import { createAsyncThunk } from "@reduxjs/toolkit";
import { positionRequests } from "../requests/positionsRequests";
import { AppDispatch } from "../../../store";
import { showNotification } from "../../notification/store/slice";
import { NotificationTypeEnum } from "../../notification/types/notificationTypes";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import {
  CreatePositionType,
  PositionType,
  UpdatePositionType,
} from "../types/positionTypes";
import { RejectWithValueErrorType } from "../../../commonTypes/rejectWithValueErrorType";

export const getPositionsListAsyncThunk = createAsyncThunk<
  PositionType[],
  undefined,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("position/getPositionsList", async (args, { rejectWithValue, dispatch }) => {
  try {
    const response = await positionRequests.getPositions();

    return response;
    //
  } catch (error: any) {
    console.error(error);
    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText:
          "Не удалось загрузить список должностей компании. Пожалуйста перезагрузите страницу или свяжитесь с администратором",
      })
    );
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});

export const createPositionAsyncThunk = createAsyncThunk<
  PositionType,
  CreatePositionType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("position/createPosition", async (args, { rejectWithValue, dispatch }) => {
  try {
    const response = await positionRequests.cretePosition(args);

    const newPosition: PositionType = {
      id: response.identifier,
      positionName: args.positionName,
    };

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Новая должность успешно создана",
      })
    );

    return newPosition;
    //
  } catch (error: any) {
    if (error.response.status === 409) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText:
            "Должность с таким названием уже существует, пожалуйста, укажите другое название.",
        })
      );
    }
    if (error.response.status === 422) {
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText: "Название должности не может быть пустым",
      });
    }
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});

export const updatePositionAsyncThunk = createAsyncThunk<
  PositionType,
  UpdatePositionType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("position/updatePosition", async (args, { rejectWithValue, dispatch }) => {
  try {
    await positionRequests.updatePosition(args);

    const updatedPosition: PositionType = {
      id: args.id,
      positionName: args.updatePositionData.positionName,
    };

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Название должности успешно обновлено",
      })
    );

    return updatedPosition;
    //
  } catch (error: any) {
    if (
      error.response.status === 404 &&
      error.response.data.error === "Data does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Эта позиция была удалена другим пользователем",
        })
      );
    }

    if (error.response.status === 409) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText:
            "Должность с таким названием уже существует, пожалуйста, укажите другое название.",
        })
      );
    }
    if (error.response.status === 422) {
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText: "Название должности не может быть пустым",
      });
    }

    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
      entityId: args.id,
    });
  }
});

export const deletePositionAsyncThunk = createAsyncThunk<
  CreateUpdateDeleteReturnOneValueType<number>,
  CreateUpdateDeleteReturnOneValueType<number>,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("position/deletePosition", async (args, { rejectWithValue, dispatch }) => {
  try {
    await positionRequests.deletePosition(args);

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Должность успешно удалена",
      })
    );

    return args;
    //
  } catch (error: any) {
    if (
      error.response.status === 404 &&
      error.response.data.error === "Data does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Эта позиция была удалена другим пользователем",
        })
      );
    }

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText:
          "Во время удаления данных произошла ошибка. \
            Пожалуйста перезагрузите страницу или свяжитесь \
            с администратором",
      })
    );
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
      entityId: args.identifier,
    });
  }
});
