import { PayloadAction } from "@reduxjs/toolkit";
import { LeadInitialStateType } from "../../types/initialState";

const updateLeadPersonsInChargeListFulfilledExtraReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<string[]>
) => {
  if (!state.lead) {
    throw new Error("Lead has not bean loaded");
  }

  state.lead.personsInCharge = action.payload;
};

export const leadPersonInChargeExtraReducers = {
  updateLeadPersonsInChargeListFulfilledExtraReducer,
};
