import { RelatedEntityTypeEnum } from "../../../../../commonTypes/relatedEntityTypes";
import { AppDispatch, RootState } from "../../../../../store";
import {
  updateDealAtDealDealsPreviewsListSSU,
  updateDealDataSSU,
} from "../../../../deal/store/slice";
import { updateFunnelStepWithRealatedEntitiesCompanyNameAndFunnelStepSSU } from "../../../../funnel/store/slice";
import { updateDealAtLeadDealsPreviewsListSSU } from "../../../../lead/store/slice";
import { UpdateDealSSUType } from "../../../types/dealTypes/dealTypes";

export const updateDealSystemUpdateHandler = (args: {
  state: RootState;
  dispatch: AppDispatch;
  ssuData: UpdateDealSSUType;
}) => {
  if (args.state.deal.deal && args.state.deal.deal.id === args.ssuData.id) {
    args.dispatch(updateDealDataSSU(args.ssuData));
    return;
  }
  if (
    args.state.deal.deal &&
    args.state.deal.deal.leadId === args.ssuData.leadId
  ) {
    args.dispatch(
      updateDealAtDealDealsPreviewsListSSU({
        id: args.ssuData.id,
        name: args.ssuData.name,
        funnelStep: args.ssuData.funnelStep,
      })
    );
  }
  if (args.state.lead.lead && args.state.lead.lead.id === args.ssuData.leadId) {
    args.dispatch(
      updateDealAtLeadDealsPreviewsListSSU({
        id: args.ssuData.id,
        name: args.ssuData.name,
        funnelStep: args.ssuData.funnelStep,
      })
    );
  }
  if (
    args.ssuData.funnelStep &&
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities &&
    args.ssuData.funnelStep.funnel.funnelId ===
      args.state.funnel.funnelWithFunnelStepsWithRelatedEntities.funnelId
  ) {
    args.dispatch(
      updateFunnelStepWithRealatedEntitiesCompanyNameAndFunnelStepSSU({
        id: args.ssuData.id,
        name: args.ssuData.name,
        funnelStep: args.ssuData.funnelStep,
        relatedEntity: RelatedEntityTypeEnum.lead,
      })
    );
  }
};
