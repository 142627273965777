import React from "react";
import { NavigateFunction } from "react-router";
import { AppDispatch } from "../../store";
import { getUserDataAsyncThunk } from "../../apps/user/store/asyncThunks/userAsyncThunks";
import { DefaultTheme } from "styled-components/dist/types";

export const insideUseEffectHandler = async (args: {
  navigate: NavigateFunction;
  dispatch: AppDispatch;
  setIsInitialized: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const accessToken = localStorage.getItem("access");
  if (accessToken === null) {
    args.navigate("login");

    args.setIsInitialized(true);
    return () => args.dispatch({ type: "socket/disconnect" });
  }
  try {
    await args.dispatch(getUserDataAsyncThunk()).unwrap();
  } catch (error: any) {
    if (error.status === 403 && error.message === "User is not active") {
      args.navigate("error");
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
    }
    if (error.status === 404 && error.message === "Data does not exist") {
      args.navigate("error");
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
    }
  }
  args.setIsInitialized(true);
  return () => args.dispatch({ type: "socket/disconnect" });
};

export const selectBaseTheme = (args: {
  themsList: DefaultTheme[];
  themeName: string;
}) => {
  const selectedTheme = args.themsList.find((theme) => {
    return theme._themeName === args.themeName;
  });

  if (!selectedTheme) {
    throw new Error("Theme does not exist");
  }

  return selectedTheme;
};
