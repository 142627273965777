import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../store";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { contactRequests } from "../requests/contactRequests";
import { setDealContactsIdsReducer } from "../../deal/store/slice";
import { NotificationTypeEnum } from "../../notification/types/notificationTypes";
import { showNotification } from "../../notification/store/slice";
import { RejectWithValueErrorType } from "../../../commonTypes/rejectWithValueErrorType";
import {
  ContactType,
  CreateContactType,
  DeleteContactType,
  UpdateContactDataType,
  UpdateContactRequestType,
  UpdateRelatedEntityContactsListType,
} from "../types/contactTypes";

export const createContactAsyncThunk = createAsyncThunk<
  ContactType,
  CreateContactType,
  {
    rejectValue: RejectWithValueErrorType;
    dispatch: AppDispatch;
  }
>("contact/createContact", async (args, { rejectWithValue, dispatch }) => {
  try {
    const response = await contactRequests.createContact(args);

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Создан новый контакт",
      })
    );

    return {
      id: response.identifier,
      ...args.contactData,
    };
  } catch (error: any) {
    if (
      error.response.status === 409 &&
      error.response?.data?.error ===
        "Row with foreign key you trying to insert does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText: "Связаный лид или сделка были удалены.",
        })
      );
    }

    if (error.response.status === 422) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText: "Email или номер телефона введены некорректно",
        })
      );
    }

    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});

export const updateContactAsyncThunk = createAsyncThunk<
  UpdateContactDataType & { id: number },
  UpdateContactRequestType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("contact/updateContact", async (args, { rejectWithValue, dispatch }) => {
  try {
    await contactRequests.updateContact(args);

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Данные контакта обновлены",
      })
    );

    return {
      id: args.additionalData.id,
      name: args.contactData.name,
      phoneNumber: args.contactData.phoneNumber,
      tgUsername: args.contactData.tgUsername,
      email: args.contactData.email,
    };
    //
  } catch (error: any) {
    if (error.response.status === 422) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText: "Email или номер телефона введены некорректно",
        })
      );
    }

    if (
      error.response.status === 404 &&
      error.response.data.error === "Data does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Контакт был удалён другим пользователем.",
        })
      );
    }
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
      entityId: args.additionalData.id,
    });
  }
});

export const deleteContactAsyncThunk = createAsyncThunk<
  CreateUpdateDeleteReturnOneValueType<number>,
  DeleteContactType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("contact/deleteContact", async (args, { rejectWithValue, dispatch }) => {
  try {
    await contactRequests.deleteContact(args);

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Контакт успешно удалён",
      })
    );

    return { identifier: args.contactId };
  } catch (error: any) {
    if (
      error.response.status === 404 &&
      error.response.data.error === "Data does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Запрашиваемый объект был удалён",
        })
      );
    }

    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
      entityId: args.contactId,
    });
  }
});

export const updateRelatedEntityContactsListAsyncThunk = createAsyncThunk<
  ContactType[],
  UpdateRelatedEntityContactsListType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "contact/updateRelatedEntityContactsListAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      args.contactsIdsToAdd = Array.from(args.contactsIdsToAdd);

      const response = await contactRequests.updateRelatedEntityContactsList(
        args
      );

      const updatedContactsIds = response.map((contact) => contact.id);

      dispatch(setDealContactsIdsReducer(updatedContactsIds));

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Список контактов обновлён",
        })
      );

      return response;
    } catch (error: any) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText:
            "Произошла ошибка, пожалуйста обновите страницу или свяжитесь с администратором",
        })
      );

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);
