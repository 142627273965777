import { axiosInstance } from "../../../api/httpCommon";
import camelize from "../../../utils/camelize";
import { LoginUserType } from "../types/userTypes";

const loginCRM = async (args: {
  username: string;
  password: string;
}): Promise<LoginUserType> => {
  const response = await axiosInstance.post("user_auth/login", args);
  const camelizedResponse = camelize(response.data);
  return camelizedResponse;
};

const authorizationRequests = {
  loginCRM,
};

export { authorizationRequests };
