import _history from "../../../../../const/history";
import { AppDispatch, RootState } from "../../../../../store";
import { LeadPreviewType } from "../../../../lead/types/leadTypes";

export const deleteLeadSystemUpdateHandler = (args: {
  state: RootState;
  dispatch: AppDispatch;
  ssuData: LeadPreviewType;
}) => {
  if (args.state.lead.lead !== undefined) {
    if (_history.navigate) {
      _history.navigate("/");
    }
  } else if (args.state.lead.leadListData?.leadsList !== undefined) {
    // DEPRECATED. Because of pagination, lead list is no longer updated
    // 
    // args.dispatch(
    //   removeDeletedLeadFromLeadListSSU({
    //     identifier: args.ssuData.id,
    //   })
    // );
  } else if (
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities !== undefined &&
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities.funnelId ===
      args.ssuData.funnelStep?.funnel.funnelId
  ) {
    // DEPRECATED. Because of pagination, funnel step's leads are no longer updated
    // 
    // args.dispatch(
    //   removeDeletedEntityFromFunnelStepWithRelatedEntitiesSSU({
    //     entityId: args.ssuData.id,
    //     funnelStepUuid: args.ssuData.funnelStep.funnelStepUuid,
    //     entityType: RelatedEntityTypeEnum.lead,
    //   })
    // );
  }
};
