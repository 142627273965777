import _history from "../../../../const/history";
import { UpdateTypeEnum, RoutersArgsType } from "../../types/types";
import { customFieldSSUTypePredicate } from "../../types/customFieldsTypes/predicates";
import {
  createCustomFieldSSU,
  updateCustomFieldSSU,
  deleteCustomFieldSSU,
} from "../../../customField/store/slice";

export const routeCustomFieldsUpdates = (args: RoutersArgsType) => {
  if (!customFieldSSUTypePredicate(args.eventData.ssuData)) {
    return;
  }

  const state = args.getState();
  if (
    args.eventData.ssuData.customFieldsContainerId !==
    state.customField.containerId
  ) {
    return;
  }
  const ssuData = {
    id: args.eventData.ssuData.id,
    fieldData: args.eventData.ssuData.fieldData,
    fieldName: args.eventData.ssuData.fieldName,
  };

  switch (args.eventData.eventType) {
    case UpdateTypeEnum.create:
      args.dispatch(createCustomFieldSSU(ssuData));
      break;

    case UpdateTypeEnum.update:
      args.dispatch(updateCustomFieldSSU(ssuData));
      break;

    case UpdateTypeEnum.delete:
      args.dispatch(
        deleteCustomFieldSSU({ identifier: args.eventData.ssuData.id })
      );
      break;

    default:
      const _exhaustiveCheck: never = args.eventData.eventType;
      throw new Error("Invalid event type");
  }
};
