import { SSUType } from "../../types";
import { FunnelWithFunnelStepsWithoutRelatedLeadsSSUType } from "./funnelWithFunnelStepsWithoutRelatedLeadsTypes";

export const funnelWithFunnelStepsWithoutRelatedLeadsSSUTypePredicate = (
  arg: SSUType
): arg is FunnelWithFunnelStepsWithoutRelatedLeadsSSUType => {
  return (
    (arg as FunnelWithFunnelStepsWithoutRelatedLeadsSSUType).funnelStepUuid !==
    undefined
  );
};
