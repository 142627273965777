import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../../../store";
import { leadRequests } from "../../requests/leadRequests";
import { setRecords } from "../../../record/store/slice";
import { RelatedEntityTypeEnum } from "../../../../commonTypes/relatedEntityTypes";
import { setContacts } from "../../../contact/store/slice";
import { showNotification } from "../../../notification/store/slice";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import {
  CreateLeadAndMainContactType,
  GetLeadAndAdditionalDataResponseType,
  LeadType,
  UpdateLeadDataType,
  UpdateLeadRequestType,
  UpdateLeadType,
} from "../../types/leadTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import { setCustomFields } from "../../../customField/store/slice";

export const getLeadAsyncThunk = createAsyncThunk<
  LeadType,
  number,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("lead/getLead", async (leadId, { rejectWithValue, dispatch }) => {
  try {
    const response = await leadRequests.getLead({ leadId: leadId });

    const leadData = {
      id: response.id,
      name: response.name,
      personsInCharge: response.personsInCharge,
      firstPaymentDate: response.firstPaymentDate,
      createdAt: response.createdAt,
      funnelStep: response.funnelStep,
      hasSellerExperience: response.hasSellerExperience,
      comment: response.comment,
      deals: response.deals,
      contactsContainerId: response.contactsContainer.id,
    };

    dispatch(
      setRecords({
        containerId: response.recordsContainer.id,
        relatedEntityData: {
          relatedEntityName: response.name,
          relatedEntityId: response.id,
          relatedEntityType: RelatedEntityTypeEnum.lead,
          recordsContainerId: response.recordsContainer.id,
        },
        records: response.recordsContainer.records,
      })
    );
    dispatch(
      setContacts({
        contactsContainerId: response.contactsContainer.id,
        contacts: response.contactsContainer.contacts,
      })
    );

    dispatch(
      setCustomFields({
        containerId: response.customFieldsContainer.id,
        customFields: response.customFieldsContainer.customFields,
        relatedEntityData: {
          recordsContainerId: response.recordsContainer.id,
          relatedEntityId: response.id,
          relatedEntityType: RelatedEntityTypeEnum.lead,
          relatedEntityName: response.name,
        },
      })
    );

    return leadData;
  } catch (error: any) {
    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText:
          "Прошизошла ошибка, пожалуйста, перезагрузите страницу.",
      })
    );
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});

export const createLeadAsyncThunk = createAsyncThunk<
  LeadType,
  CreateLeadAndMainContactType,
  {
    rejectValue: RejectWithValueErrorType;
    state: RootState;
    distapth: AppDispatch;
  }
>(
  "lead/CreateLead",
  async (createLeadData, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState().user.user;
      if (!user || !user.username) {
        throw new Error("User not found. Please contact with administarator");
      }

      const response = await leadRequests.createLead(createLeadData);

      const createdLead: GetLeadAndAdditionalDataResponseType = {
        id: response.id,
        name: createLeadData.leadData.name,
        comment: null,
        firstPaymentDate: null,
        hasSellerExperience: false,
        createdAt: response.createdAt,
        funnelStep: null,
        deals: [],
        personsInCharge: response.personsInCharge,
        customFieldsContainer: {
          id: response.customFieldsContainerId,
          customFields: [],
        },
        contactsContainer: {
          id: response.contactsContainerId,
          contacts: [
            {
              id: response.contactId,
              name: createLeadData.contactData.name,
              email: createLeadData.contactData.email,
              phoneNumber: createLeadData.contactData.phoneNumber,
              tgUsername: createLeadData.contactData.tgUsername,
            },
          ],
        },
        recordsContainer: {
          id: response.recordsContainerId,
          records: [],
        },
        // contactsContainerId: response.contactsContainerId,
      };

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Лид был успешно создан",
        })
      );

      return createdLead;
    } catch (error: any) {
      if (
        error.response.status === 409 &&
        error.response.data.error === "Inserted data must be unique"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Лид с таким именем уже существует.",
          })
        );
      }
      if (error.response.status === 422) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText:
              "Введён некорректный email или номер телефона контакта.",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const updateLeadAsyncThunk = createAsyncThunk<
  UpdateLeadDataType,
  UpdateLeadType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("lead/updateLead", async (args, { rejectWithValue, dispatch }) => {
  try {
    if (
      args.updateData.personsInCharge &&
      !args.updateData.personsInCharge.length
    ) {
      throw new Error("no persons in charge error");
    }
    const updateData: UpdateLeadRequestType = {
      comment: args.updateData.comment || null,
      name: args.updateData.name || null,
      firstPaymentDate: args.updateData.firstPaymentDate || null,
      funnelStepUuid: args.updateData.funnelStep?.funnelStepUuid || null,
      hasSellerExperience:
        args.updateData.hasSellerExperience === undefined
          ? null
          : args.updateData.hasSellerExperience,
      personsInCharge: args.updateData.personsInCharge,
    };

    await leadRequests.updateLead({
      updateData: updateData,
      updatedEntityId: args.leadId,
      recordsContainerId: args.recordsContainerId,
    });

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Данные лида успешно изменены",
      })
    );

    return args.updateData;
  } catch (error: any) {
    if (error.response.message.data === "no persons in charge error") {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText: "Укажите хотя бы одного ответственнгого",
        })
      );
    }
    if (
      error.response.status === 404 &&
      error.response.data.error === "Data does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText: "Лид был удалён",
        })
      );
    }

    if (error.response.status === 409) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText: "Лид с таким именем уже существует",
        })
      );
    }

    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});

// export const deleteLead = createAsyncThunk<
//   number,
//   number,
//   { rejectValue: Error | AxiosError }
// >("lead/deleteLead", async (leadId, { rejectWithValue }) => {
//   try {
//     const response = await leadRequests.deleteLead(leadId);

//     return response.identifier;
//   } catch (error: any) {
//     console.log(error);
//     return rejectWithValue(error);
//   }
// });
