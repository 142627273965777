import snakeize from "../../../utils/snakeize";
import { axiosInstance } from "../../../api/httpCommon";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import {
  ContactPreviewType,
  ContactType,
  CreateContactType,
  DeleteContactType,
  UpdateContactRequestType,
  UpdateRelatedEntityContactsListType,
} from "../types/contactTypes";
import camelize from "../../../utils/camelize";
import { AxiosError } from "axios";

const getContactsPreviews = async (args: {
  contactsContainerId: number;
}): Promise<ContactPreviewType[]> => {
  const response = await axiosInstance.get(
    `/contact/get-contacts-previews/${args.contactsContainerId}`
  );

  const camelizedData = camelize(response.data);
  return camelizedData;
};

const createContact = async (
  args: CreateContactType
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedData = snakeize(args.contactData);
  const response = await axiosInstance.post(
    `/contact/create-contact?records_container_id=${args.additionalData.recordsContainerId}`,
    snakeizedData
  );
  
  return response.data;
};

const updateContact = async (
  args: UpdateContactRequestType
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedData = snakeize(args.contactData);

  delete snakeizedData.id;

  const response = await axiosInstance.patch(
    `/contact/update-contact/${args.additionalData.id}?records_container_id=${args.additionalData.recordsContainerId}`,
    snakeizedData
  );

  return response.data;
};

const deleteContact = async (
  args: DeleteContactType
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const response = await axiosInstance.delete(
    `/contact/delete-contact/${args.contactId}?records_container_id=${args.recordsContainerId}`
  );

  return response.data;
};

const updateRelatedEntityContactsList = async (
  args: UpdateRelatedEntityContactsListType
): Promise<ContactType[]> => {
  const snakeizedData = snakeize(args);

  const response = await axiosInstance.patch(
    `/contact/update-related-entity-contacts-list?records_container_id=${args.recordsContainerId}`,
    snakeizedData
  );
  const camelizedData = camelize(response.data);

  return camelizedData;
};

export const contactRequests = {
  getContactsPreviews,
  createContact,
  updateContact,
  deleteContact,
  updateRelatedEntityContactsList,
};
