import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequests } from "../../requests/userRequests";
import { AppDispatch } from "../../../../store";
import { authorizationRequests } from "../../requests/authorizationRequests";
import { showNotification } from "../../../notification/store/slice";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import {
  LoginType,
  LoginUserType,
  UpdateUserAvatarResponseType,
  UpdateUserAvatarType,
  UpdateUserPasswordType,
  UpdateUserType,
  UserWithTasksType,
} from "../../types/userTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";

export const getUserDataAsyncThunk = createAsyncThunk<
  UserWithTasksType,
  undefined,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("user/getUserDataAsyncThunk", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await userRequests.getUserData();
    dispatch({ type: "socket/connect" });
    return response;
  } catch (error: any) {
    if (
      error.response.status === 403 &&
      error.response.data.error === "User is not active"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Пользоватесь заблокирован",
        })
      );
    }
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});

export const updateUserDataAsyncThunk = createAsyncThunk<
  UpdateUserType,
  UpdateUserType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "user/updateUserDataAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await userRequests.updateUserData(args);

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Данные пользоватеся успешно обновлены",
        })
      );
      return args;
    } catch (error: any) {
      if (
        error.response.status === 403 &&
        error.response.data.error === "User is not active"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Пользоватесь заблокирован",
          })
        );
      }
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Пользователь был удалён",
          })
        );
      }
      if (error.response.status === 422) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Введён некорректный номер телефона или email",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);
export const updateUserAvatarAsyncThunk = createAsyncThunk<
  UpdateUserAvatarResponseType,
  UpdateUserAvatarType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "user/updateUserAvatarAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response = await userRequests.updateUserAvatar(args);

      return response;
    } catch (error: any) {
      if (
        error.response.status === 403 &&
        error.response.data.error === "User is not active"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Пользоватесь заблокирован",
          })
        );
      }
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Пользователь был удалён",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const updateUserPasswordAsyncThunk = createAsyncThunk<
  undefined,
  UpdateUserPasswordType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "user/updateUserPasswordAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      await userRequests.updateUserPassword(args);
    } catch (error: any) {
      if (
        error.response.status === 401 &&
        error.response.data.error === "Password is not valid"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Текущий пароль указан неверно.",
          })
        );
      }
      if (
        error.response.status === 403 &&
        error.response.data.error === "User is not active"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Пользоватесь заблокирован",
          })
        );
      }
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText: "Пользователь был удалён",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const loginAsyncThunk = createAsyncThunk<
  LoginUserType,
  LoginType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("user/login", async (args, { rejectWithValue, dispatch }) => {
  try {
    const response = await authorizationRequests.loginCRM(args);
    return response;
  } catch (error: any) {
    if (
      error.response.status === 401 &&
      error.response.data.error === "Password is not valid"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText: "Текущий пароль указан неверно.",
        })
      );
    }
    if (
      error.response.status === 403 &&
      error.response.data.error === "User is not active"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Пользоватесь заблокирован",
        })
      );
    }
    if (
      error.response.status === 404 &&
      error.response.data.error === "Data does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Запрашиваемый объект был удалён",
        })
      );
    }

    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});
