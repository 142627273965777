import React, { useCallback } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/typedHooks";
import { insideUseEffectHandler, selectBaseTheme } from "./services";
import { reactLazyComponents } from "./componentLazyLoading";
import { baseThemeDark } from "../../theme/baseThemeDark";
import Loader from "../loader/Loader";
import _history from "../../const/history";
import { ThemeProvider } from "styled-components";
import { StyledApp } from "./App.style";
import { baseThemeLight } from "../../theme/baseThemeLight";
import { ThemeNameType } from "./localTypes";

const App: React.FC = () => {
  const storedThemeName: ThemeNameType =
    (localStorage.getItem("theme") as ThemeNameType | null) || "light";

  const [currentThemeName, setCurrentThemeName] =
    React.useState<ThemeNameType>(storedThemeName);

  const [initialized, setIsInitialized] = React.useState<boolean>(false);

  const navigate = useNavigate();

  _history.navigate = navigate;

  const currentTheme = selectBaseTheme({
    themeName: storedThemeName,
    themsList: [baseThemeDark, baseThemeLight],
  });

  const onClickChangeThemeButtonHandler = useCallback(() => {
    if (currentThemeName === "light") {
      localStorage.setItem("theme", "dark");
      setCurrentThemeName("dark");
    } else {
      localStorage.setItem("theme", "light");
      setCurrentThemeName("light");
    }
  }, [currentThemeName]);

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    (async () => {
      insideUseEffectHandler({ navigate, dispatch, setIsInitialized });
    })();
  }, []);

  if (!initialized) {
    return <Loader size={80} color={baseThemeDark.colorPrimaryBackground} />;
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <StyledApp>
        {initialized ? (
          <Routes>
            <Route
              path="login"
              element={
                <React.Suspense
                  fallback={
                    <Loader
                      size={80}
                      color={baseThemeDark.colorPrimaryBackground}
                    />
                  }
                >
                  <reactLazyComponents.Login />
                </React.Suspense>
              }
            />
            <Route
              path="/"
              element={
                <React.Suspense
                  fallback={
                    <Loader
                      size={80}
                      color={baseThemeDark.colorPrimaryBackground}
                    />
                  }
                >
                  <reactLazyComponents.MainLayout
                    onClickChangeThemeButtonHandler={
                      onClickChangeThemeButtonHandler
                    }
                  />
                </React.Suspense>
              }
            >
              <Route
                index
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.LeadList />
                  </React.Suspense>
                }
              />

              <Route
                path="/user/tasks"
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.Tasks />
                  </React.Suspense>
                }
              />
              <Route
                path="/user/info"
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.User />
                  </React.Suspense>
                }
              />
              <Route
                path={`admin/users-list`}
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.AdminUsersList />
                  </React.Suspense>
                }
              />
              <Route
                path={`admin/create-user`}
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.AdminCreateUser />
                  </React.Suspense>
                }
              />
              <Route
                path="leads/create-new-lead"
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.CreateLeadForm />
                  </React.Suspense>
                }
              />
              <Route
                path="leads/lead/:id"
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.Lead />
                  </React.Suspense>
                }
              />
              <Route
                path="deals/deal/:id"
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.Deal />
                  </React.Suspense>
                }
              />
              <Route
                path="/deals/create-new-deal"
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.CreateDealForm />
                  </React.Suspense>
                }
              />
              <Route
                path="funnels/funnels-list"
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.FunnelList />
                  </React.Suspense>
                }
              />
              <Route
                path="funnels/create-funnel"
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.CreateFunnelPage />
                  </React.Suspense>
                }
              />
              <Route
                path={`/funnels/edit-funnel/:id`}
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.EditFunnelPage />
                  </React.Suspense>
                }
              />

              <Route
                path={`admin/positions`}
                element={
                  <React.Suspense
                    fallback={
                      <Loader
                        size={80}
                        color={baseThemeDark.colorPrimaryBackground}
                      />
                    }
                  >
                    <reactLazyComponents.PositionActions />
                  </React.Suspense>
                }
              />
            </Route>
            <Route
              path="/error"
              element={
                <React.Suspense
                  fallback={
                    <Loader
                      size={80}
                      color={baseThemeDark.colorPrimaryBackground}
                    />
                  }
                >
                  <reactLazyComponents.ErrorPage />
                </React.Suspense>
              }
            />
            <Route
              path="*"
              element={
                <React.Suspense
                  fallback={
                    <Loader
                      size={80}
                      color={baseThemeDark.colorPrimaryBackground}
                    />
                  }
                >
                  <reactLazyComponents.Page404 />
                </React.Suspense>
              }
            />
          </Routes>
        ) : (
          <Loader size={200} color={baseThemeDark.colorPrimaryBackground} />
        )}
      </StyledApp>
    </ThemeProvider>
  );
};

export default App;
