import { axiosInstance } from "../../../api/httpCommon";
import camelize from "../../../utils/camelize";
import snakeize from "../../../utils/snakeize";
import {
  PersonInChargePreviewType,
  UpdateDealPersonsInChargeListType,
} from "../types/dealPersonInChargeTypes";

export const getUsernamesForSelectUserList = async (args: {
  requiredUsernameOrItsPart: string;
}): Promise<PersonInChargePreviewType[]> => {
  const response = await axiosInstance.get(
    `/user/get-usernames?username=${args.requiredUsernameOrItsPart}`
  );
  return response.data;
};

export const updateDealPersonsInCharge = async (
  args: UpdateDealPersonsInChargeListType
): Promise<string[]> => {
  const snaikeizedData = snakeize(args.updateDealPersonsInChargeLists);

  const response = await axiosInstance.patch(
    `/deal-person-in-charge/update-deal-persons-in-charge-list/${args.dealId}?deal_records_container_id=${args.dealRecordsContainerId}`,
    snaikeizedData
  );

  const camelizedData = camelize(response.data);

  return camelizedData;
};

export const dealPersonInChargeRequests = {
  getUsernamesForSelectUserList,
  updateDealPersonsInCharge,
};
