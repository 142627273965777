import { PayloadAction } from "@reduxjs/toolkit";
import { LeadStoreEnumTypes } from "../../types/leadTypes";
import { setObjectFieldsUndefined } from "../../../../utils/setObjectsFieldsUndefined";
import { LeadInitialStateType } from "../../types/initialState";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { deleteListItemUsingForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import {
  DealPreviewType,
  UpdateDealPreviewSUUType,
} from "../../types/leadDealTypes";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";

const setUndefinedWhenLeavingPageReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<{
    entityType: Partial<LeadStoreEnumTypes[]>;
  }>
) => {
  setObjectFieldsUndefined({
    obj: state,
    entitiesList: action.payload.entityType,
  });
};

const addNewDealToLeadDealsListSSUReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<DealPreviewType>
) => {
  if (!state.lead) {
    return;
  }
  state.lead.deals.push(action.payload);
};

const updateDealAtLeadDealsPreviewsListSSUReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<UpdateDealPreviewSUUType>
) => {
  if (!state.lead) {
    return;
  }
  const dealForUpdate = findElementInListUsingBuiltInFindMethod({
    list: state.lead.deals,
    lookForParam: "id",
    lookForParamValue: action.payload.id,
  });

  if (!dealForUpdate) {
    return;
  }

  updateObjectDataByNewDataContainsNullAndUndefined({
    data: dealForUpdate,
    newUpdateData: {
      funnelStep: action.payload.funnelStep,
      name: action.payload.name,
    },
  });
};

const deleteDealFromLeadDealsListSSUReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<number>>
) => {
  if (!state.lead) {
    return;
  }
  
  const deletedDealPreviewAndUpdatedDealPreviewsArray =
  deleteListItemUsingForAndSpliceMethods({
    filterParam: "id",
    filterParamValue: action.payload.identifier,
    list: state.lead.deals,
  });
  console.log(deletedDealPreviewAndUpdatedDealPreviewsArray);

  if (!deletedDealPreviewAndUpdatedDealPreviewsArray) {
    return;
  }

  state.lead.deals = deletedDealPreviewAndUpdatedDealPreviewsArray.updatedArray;
};


export const leadReducers = {
  setUndefinedWhenLeavingPageReducer,
  // SSU reducers
  addNewDealToLeadDealsListSSUReducer,
  updateDealAtLeadDealsPreviewsListSSUReducer,
  deleteDealFromLeadDealsListSSUReducer,
};
