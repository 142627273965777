import axios from "axios";
import _history from "../const/history";
import { baseURL, errorMessage } from "./const";

const options = {
  baseURL: baseURL,
};

export const axiosInstance = axios.create(options);

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access");
  if (accessToken) {
    config.headers.Authorization = "Bearer " + accessToken;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const navigate = _history.navigate!;
    const refreshToken = localStorage.getItem("refresh");
    // Unhandled server error
    if (error.code === "ERR_NETWORK") {
      navigate("/error", {
        state: {
          message: errorMessage,
        },
      });
      return Promise.reject(error);
    }
    // Never used. If authorization header has not been provided
    if (
      error.response.status === 400 &&
      error.response.data.error ===
        "'Authorization' header has not been provided or not valid"
    ) {
      navigate("/login");
      return Promise.reject(error);
    }
    if (
      error.response.status === 403 &&
      error.response.data.error === "User has been disabled."
    ) {
      // User has been blocked
      navigate("/error", {
        state: { message: "Пользователь заблокирован." },
      });
      return Promise.reject(error);
    }

    // Token data does not exists at local storage
    if (!refreshToken) {
      navigate("/login");
      return Promise.reject(error);
    }

    if (
      error.response.status === 403 &&
      error.response.data.error === "Token has been expired."
    ) {
      try {
        try {
          const res = await axios.post(`${baseURL}user_auth/refresh`, {
            refresh_token: refreshToken,
          });

          localStorage.setItem("access", res.data.access);

          return await axiosInstance(error.config);
        } catch (error: any) {
          if (
            error.response.status === 403 &&
            (error.response.data.error === "Token has been expired." ||
              error.response.data.error ===
                "Token is not valid. Token verification failed.")
          ) {
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            navigate("/login");
          }
          navigate("/error", {
            state: {
              message: errorMessage,
            },
          });
        }

        return Promise.reject(error);
      } catch (error) {
        navigate("/error");
        return Promise.reject(error);
      }

    }
    return Promise.reject(error);
    
  }
);

// if (error.code === "403" && error.response.data) {
// }
// if (error.code === "ERR_BAD_REQUEST" && error.response?.status === 400) {
//   navigate("/login");
//   setIsInitialized(true);
//   return () => dispatch({ type: "socket/disconnect" });
// }
