import { PayloadAction } from "@reduxjs/toolkit";
import { CustomFieldInitialStateType } from "../types/initialStateType";

const setCustomFieldsReducer = (
  state: CustomFieldInitialStateType,
  action: PayloadAction<CustomFieldInitialStateType>
) => {
  state.containerId = action.payload.containerId;
  state.relatedEntityData = action.payload.relatedEntityData;
  state.customFields = action.payload.customFields;
};

const setCustomFieldsStateUndefinedWhenLeavingLeadOrDealPageReducer = (
  state: CustomFieldInitialStateType,
  action: PayloadAction<undefined>
) => {
  state.containerId = undefined;
  state.relatedEntityData = undefined;
  state.customFields = undefined;
};

export const customFieldsReducers = {
  setCustomFieldsReducer,
  setCustomFieldsStateUndefinedWhenLeavingLeadOrDealPageReducer,
};
