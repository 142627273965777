import { SSUType } from "../types";
import { ContactDealSSUType } from "./contactDealTypes";

export const contactDealSSUTypePredicate = (
  arg: SSUType
): arg is ContactDealSSUType => {
  return (
    (arg as ContactDealSSUType).contactsList !== undefined &&
    (arg as ContactDealSSUType).dealId !== undefined
  );
};
