import { createSlice } from "@reduxjs/toolkit";
import { notificationInitialState } from "./initialState";
import { notificationReducers } from "./reducers";

const notificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitialState,
  reducers: {
    showNotification: notificationReducers.showNotificationReducer,
    hideNotification: notificationReducers.hideNotificationReducer,
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
