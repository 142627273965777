import { PayloadAction } from "@reduxjs/toolkit";
import { NotificationInitialStateType } from "../types/initialStateTypes";
import {
  NotificationColorEnum,
  NotificationTitleEnum,
  NotificationType,
  ShowNotificationData,
} from "../types/notificationTypes";

export const showNotificationReducer = (
  state: NotificationInitialStateType,
  action: PayloadAction<ShowNotificationData>
) => {
  const notification: NotificationType = {
    isVisible: true,
    notificationText: action.payload.notificationText,
    notificationTitle: NotificationTitleEnum[action.payload.notificationType],
    notificationBackgroundColor:
      NotificationColorEnum[action.payload.notificationType],
  };
  state.notification = notification;
};

export const hideNotificationReducer = (
  state: NotificationInitialStateType,
  action: PayloadAction<undefined>
) => {
  state.notification.isVisible = false;
};

export const notificationReducers = {
  showNotificationReducer,
  hideNotificationReducer,
};
