import { createSlice } from "@reduxjs/toolkit";
import { contactInitialState } from "./initialState";
import { contactFulfilledExtraReducers } from "./extraReducers/fulfilledExtraReducers";
import {
  createContactAsyncThunk,
  updateContactAsyncThunk,
  deleteContactAsyncThunk,
  updateRelatedEntityContactsListAsyncThunk,
} from "./asyncThunks";
import { constactReducers } from "./reducers";
import { contactRejectedExtraReducers } from "./extraReducers/rejectedExtraReducers";

const contactSlice = createSlice({
  name: "lead",
  initialState: contactInitialState,
  reducers: {
    setContacts: constactReducers.setContactsReducer,
    setContactsStateUndefinedWhenLeavingLeadOrDealPage:
      constactReducers.setContactsStateUndefinedWhenLeavingLeadOrDealPageReducer,
    // Websocket server sent updates reducers
    createContactSSU: contactFulfilledExtraReducers.createContactExtraReducer,
    updateContactSSU: contactFulfilledExtraReducers.updateContactExtraReducer,
    deleteContactSSU: contactFulfilledExtraReducers.deleteContactExtraReducer,

    updateRelatedEntityContactsListSSU:
      contactFulfilledExtraReducers.updateRelatedEntityContactsListExtraReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(
      createContactAsyncThunk.fulfilled,
      contactFulfilledExtraReducers.createContactExtraReducer
    );
    // builder.addCase(createContactAsyncThunk.rejected, (state, action) => {
    //   console.log(action.payload);
    // });

    builder.addCase(
      updateContactAsyncThunk.fulfilled,
      contactFulfilledExtraReducers.updateContactExtraReducer
    );
    builder.addCase(
      updateContactAsyncThunk.rejected,
      contactRejectedExtraReducers.updateDeleteContactRejectedExtraReducer
    );

    builder.addCase(
      deleteContactAsyncThunk.fulfilled,
      contactFulfilledExtraReducers.deleteContactExtraReducer
    );
    builder.addCase(
      deleteContactAsyncThunk.rejected,
      contactRejectedExtraReducers.updateDeleteContactRejectedExtraReducer
    );

    builder.addCase(
      updateRelatedEntityContactsListAsyncThunk.fulfilled,
      contactFulfilledExtraReducers.updateRelatedEntityContactsListExtraReducer
    );
  },
});

export const {
  setContacts,
  setContactsStateUndefinedWhenLeavingLeadOrDealPage,

  createContactSSU,
  updateContactSSU,
  deleteContactSSU,

  updateRelatedEntityContactsListSSU,
} = contactSlice.actions;

export default contactSlice.reducer;
