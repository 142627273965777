import { RelatedEntityTypeEnum } from "../../../../../commonTypes/relatedEntityTypes";
import _history from "../../../../../const/history";
import { AppDispatch, RootState } from "../../../../../store";
import { deleteDealFromDealDealsListSSU } from "../../../../deal/store/slice";
import { DeleteDealType } from "../../../../deal/types/dealTypes";
import { removeDeletedEntityFromFunnelStepWithRelatedEntitiesSSU } from "../../../../funnel/store/slice";
import { deleteDealFromLeadDealsListSSU } from "../../../../lead/store/slice";

export const deleteDealSystemUpdateHandler = (args: {
  state: RootState;
  dispatch: AppDispatch;
  ssuData: DeleteDealType;
}) => {

  // deal page is opened, deal with the same id has been deleted
  if (args.state.deal.deal && args.state.deal.deal.id === args.ssuData.id) {
    if (_history.navigate) {
      _history.navigate("/");
    }
    return;
  }
  //   deal page is opened. deal with the another id has been deleted
  if (
    args.state.deal.deal &&
    args.state.deal.deal.leadId === args.ssuData.leadId
  ) {
    args.dispatch(
      deleteDealFromDealDealsListSSU({
        identifier: args.ssuData.id,
      })
    );
    return;
  }

  //   lead page is opened, deal of the lead has been deleted
  if (args.state.lead.lead && args.state.lead.lead.id === args.ssuData.leadId) {
    
    args.dispatch(
      deleteDealFromLeadDealsListSSU({ identifier: args.ssuData.id })
    );
    return;
  }
  //   funnel page is opened
  if (
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities &&
    args.ssuData.funnelStepUuid &&
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities.funnelId ===
      args.ssuData.funnelId
  ) {
    args.dispatch(
      removeDeletedEntityFromFunnelStepWithRelatedEntitiesSSU({
        entityId: args.ssuData.id,
        funnelStepUuid: args.ssuData.funnelStepUuid,
        entityType: RelatedEntityTypeEnum.deal,
      })
    );
  }
};
