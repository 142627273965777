import React from "react";
import StyledLoader from "./Loader.style";
import { ClipLoader } from "react-spinners";
import { Props } from "./localTypes";


const Loader: React.FC<Props> = (props) => {
  return (
    <StyledLoader>
      <ClipLoader color={props.color} size={props.size} />
    </StyledLoader>
  );
};

export default React.memo(Loader);
