import { CommonTheme } from "./types/types";

export const themeCommon: CommonTheme = {
  mediaMaxWidth: "1440px",
  mediaMaxWidth834: "834px",
  mediaMaxWidth320: "320px",

  widthFor1440: "1280px",
  widthFor834: "807.5px",
  widthFor320: "290px",

  colorDarkTransparent: "#0d182148",
  colorLightTransparent: "#49494948",

  fontWeightThin: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,

  fontSize12: "12px",
  fontSize14: "14px",
  fontSize16: "16px",
  fontSize18: "18px",
  fontSize20: "20px",
  fontSize22: "22px",
  fontSize27: "27px",
  fontSize32: "32px",
  fontSize36: "36px",
  fontSize40: "40px",

  borderRadius: "0px",
  border: "3px solid #766d61",

  transitionStyle: "all 0.15s ease-in-out",
};
