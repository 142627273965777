import { PayloadAction } from "@reduxjs/toolkit";
import { UserInitialStateType } from "../../types/initialStateType";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";
import { clearDataFromNullUndefinedFields } from "../../../../utils/clearDataFromNullUndefinedFields";
import { setUserData } from "./services";
import {
  LoginUserType,
  UpdateUserAvatarResponseType,
  UpdateUserType,
  UserWithTasksType,
} from "../../types/userTypes";
import { UpdateUserDataAdminType } from "../../types/userAdminTypes";

const loginFulfilledExtraReducer = (
  state: UserInitialStateType,
  action: PayloadAction<LoginUserType>
) => {
  localStorage.setItem("access", action.payload.tokenData.access);
  localStorage.setItem("refresh", action.payload.tokenData.refresh);

  setUserData({
    state: state,
    user: action.payload.userData,
  });
};

const getUserDataFulfilledExtraReducer = (
  state: UserInitialStateType,
  action: PayloadAction<UserWithTasksType>
) => {
  setUserData({
    state: state,
    user: action.payload,
  });
};

const updateUserDataFulfilledExtraReducer = (
  state: UserInitialStateType,
  action: PayloadAction<UpdateUserType | UpdateUserDataAdminType>
) => {
  if (!state.user) {
    throw new Error("User does not extsi");
  }
  
  updateObjectDataByNewDataContainsNullAndUndefined({
    data: state.user,
    newUpdateData: action.payload,
  });
};

const updateUserAvatarExtraReducer = (
  state: UserInitialStateType,
  action: PayloadAction<UpdateUserAvatarResponseType>
) => {
  if (!state.user) {
    throw new Error("User does not exist, please reload the page");
  }
  state.user.avatar = action.payload.avatar;
};

export const userExtraReducers = {
  loginFulfilledExtraReducer,
  getUserDataFulfilledExtraReducer,
  updateUserDataFulfilledExtraReducer,
  updateUserAvatarExtraReducer,
};
