import { SSUType } from "../types";
import { LeadPersonsInChargeListType } from "./leadPersonsInChargeTypes";

export const leadPersonsInChargeSSUTypePredicate = (
  arg: SSUType
): arg is LeadPersonsInChargeListType => {
  return (
    (arg as LeadPersonsInChargeListType).personsInChargeList !== undefined &&
    (arg as LeadPersonsInChargeListType).leadId !== undefined
  );
};
