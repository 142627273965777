import { AppDispatch, RootState } from "../../../../../store";
import { updateLeadSSU } from "../../../../lead/store/slice";
import { LeadDataSSUType } from "../../../types/leadTypes/leadDataTypes";

export const updateLeadSystemUpdateHandler = (args: {
  state: RootState;
  dispatch: AppDispatch;
  ssuData: LeadDataSSUType;
}) => {
  if (
    args.state.lead.lead !== undefined &&
    args.state.lead.lead.id === args.ssuData.id
  ) {
    args.dispatch(
      updateLeadSSU({
        comment: args.ssuData.comment,
        name: args.ssuData.name,
        firstPaymentDate: args.ssuData.firstPaymentDate,
        funnelStep: args.ssuData.funnelStep,
        hasSellerExperience: args.ssuData.hasSellerExperience,
      })
    );

    //
  } else if (args.state.lead.leadListData?.leadsList !== undefined) {
    return;
    // DEPRECATED. Because of pagination, lead list is no longer updated
    //
    // args.dispatch(
    //   updateLeadListItemSSU({
    //     name: args.ssuData.name,
    //     funnelStep: args.ssuData.funnelStep,
    //     id: args.ssuData.id,
    //   })
    // );
    //
  } else if (
    args.ssuData.funnelStep &&
    args.state.funnel.funnelWithFunnelStepsWithRelatedEntities &&
    args.ssuData.funnelStep.funnel.funnelId ===
      args.state.funnel.funnelWithFunnelStepsWithRelatedEntities.funnelId
  ) {
    return;
    // DEPRECATED. Because of pagination, funnel step's leads are no longer updated
    //
    // args.dispatch(
    //   updateFunnelStepWithRealatedEntitiesCompanyNameAndFunnelStepSSU({
    //     id: args.ssuData.id,
    //     name: args.ssuData.name,
    //     funnelStep: args.ssuData.funnelStep,
    //     relatedEntity: RelatedEntityTypeEnum.lead,
    //   })
    // );
  }
};
