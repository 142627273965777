import { updateLeadPersonsInChargeSSU } from "../../../lead/store/slice";
import { leadPersonsInChargeSSUTypePredicate } from "../../types/leadPersonsInChargeTypes/predicates";
import { RoutersArgsType, UpdateTypeEnum } from "../../types/types";

export const routeLeadPersonsInChargeUpdates = (args: RoutersArgsType) => {
  const state = args.getState();

  if (
    !leadPersonsInChargeSSUTypePredicate(args.eventData.ssuData) ||
    !state.lead.lead
  ) {
    return;
  }

  if (state.lead.lead?.id !== args.eventData.ssuData.leadId) {
    return;
  }

  args.dispatch(
    updateLeadPersonsInChargeSSU(args.eventData.ssuData.personsInChargeList)
  );
};
