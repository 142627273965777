import { UserInitialStateType } from "../types/initialStateType";

export const initialState: UserInitialStateType = {
  user: undefined,
  userTasks: {
    futureTasks: [],
    overduedTasks: [],
    todayTasks: [],
  },
  userAdminData: {
    positionsList: undefined,
    userPreviewsList: undefined,
  },
};
