import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";

import { funnelStepExtraReducers } from "./extraReducers/funnelStepExtraReducers";
import { funnelExtraReducers } from "./extraReducers/funnelExtraReducers";
import {
  deleteFunnelAsyncThunk,
  updateFunnelNameAsyncThunk,
  getFunnelWithRelatedFunnelStepsWithoutRelatedLeadsAsyncThunk,
  getFunnelsListAsycnThunk,
} from "./asyncThunks/funnelAsyncThunks";
import {
  getFunnelStepsWithRelatedLeadsAsyncThunk,
  changeEntityFunnelStepByDragAndDropAsyncThunk,
  changeFunnelStepOrderByDragAndDropAsyncThunk,
  updateFunnelStepNameAsyncThunk,
  deleteFunnelStepAsyncThunk,
  createFunnelStepAsyncThunk,
} from "./asyncThunks/funnelStepAsyncThunks";
import { funnelStepReducers } from "./reducers/funnelStepReducers";
import { funnelReducers } from "./reducers/funnelReducers";

const funnelSlice = createSlice({
  name: "funnel",
  initialState: initialState,
  reducers: {
    setUndefinedWhenLeavingPage:
      funnelReducers.setUndefinedWhenLeavingPageReducer,

    updateFunnelStepWithRealatedEntitiesCompanyNameAndFunnelStepSSU:
      funnelStepReducers.updateFunnelStepWithRealatedEntitiesNameAndFunnelStepSSUReducer,
    removeDeletedEntityFromFunnelStepWithRelatedEntitiesSSU:
      funnelStepReducers.removeDeletedEntityFromFunnelStepWithRelatedEntitiesSSUReducer,

    // FUNNELS PAGE WITH FUNNEL STEPS WITH RELATED LEADS
    // add fs
    addNewFunnelStepWithRelatedLeadsToFunnelStepsArraySSU:
      funnelStepReducers.addNewFunnelStepWithRelatedEntitiesToFunnelStepsArraySSUReducer,
    // update funnel step name
    updateFunnelStepWithRelatedLeadsNameSSU:
      funnelStepReducers.updateFunnelStepWithRelatedEntitiesNameSSUReducer,
    // update funnes steps order
    updateFunnelStepsOrderAtFunnelStepsWithRelatedLeadsSSU:
      funnelStepReducers.updateFunnelStepsOrderAtFunnelStepsWithRelatedEntitiesSSUReducer,
    // delete fs
    deleteFunnelStepWithRelatedLeadsFromFunnelStepsArraySSU:
      funnelStepReducers.deleteFunnelStepWithRelatedEntitiesFromFunnelStepsArraySSUReducer,

    // EDIT FUNNEL PAGE
    // add fs
    addFunnelStepToFunnelStepsWithoutRelatedLeadsArraySSU:
      funnelStepExtraReducers.createFunnelStepFulfilledExtraReducer,
    // update fs name
    updateFunnelStepNameWithoutRelatedLeadsSSU:
      funnelStepExtraReducers.updateFunnelStepNameFulfilledExtraReducer,
    // update fs order
    updateFunnelStepsOrderAtFunnelStepsWithoutRelatedLeadsSSU:
      funnelStepExtraReducers.changeFunnelStepOrderByDragAndDropFulfilledExtraReducer,
    // delete fs
    deleteFunnelStepWithoutRelatedLeadsFromFunnelStepsArraySSU:
      funnelStepExtraReducers.deleteFunnelStepFulfilledExtraReducer,

    addNewFunnelToFunnelsListSSU:
      funnelReducers.addNewFunnelToFunnelsListSSUReducer,
    updateFunnelNameInFunnelsListSSU:
      funnelReducers.updateFunnelNameInFunnelsListSSUReducer,
    updateFunnelWithFunnelStepsWithoutRelatedLeadsSSU:
      funnelReducers.updateFunnelWithFunnelStepsWithoutRelatedLeadsSSUReducer,
    deleteFunnelFromFunnelsListSSU:
      funnelReducers.deleteFunnelFromFunnelsListSSUReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFunnelsListAsycnThunk.fulfilled,
      funnelExtraReducers.getFunnelsListFulfilled
    );
    builder.addCase(
      getFunnelStepsWithRelatedLeadsAsyncThunk.fulfilled,
      funnelExtraReducers.getFunnelStepsWithRelatedLeadsFulfilled
    );
    builder.addCase(
      getFunnelStepsWithRelatedLeadsAsyncThunk.rejected,
      (state, action) => {
        console.log(action.error);
      }
    );
    builder.addCase(
      updateFunnelNameAsyncThunk.fulfilled,
      funnelExtraReducers.editFunnelNameFulfilled
    );
    builder.addCase(updateFunnelNameAsyncThunk.rejected, (state, action) => {
      console.log(action.error.message);
    });
    builder.addCase(
      deleteFunnelAsyncThunk.fulfilled,
      funnelExtraReducers.deleteFunnelFulfilled
    );
    builder.addCase(deleteFunnelAsyncThunk.rejected, (state, action) => {
      console.log(action.error.message);
    });
    builder.addCase(
      createFunnelStepAsyncThunk.fulfilled,
      funnelStepExtraReducers.createFunnelStepFulfilledExtraReducer
    );
    builder.addCase(
      changeEntityFunnelStepByDragAndDropAsyncThunk.fulfilled,
      funnelStepExtraReducers.changeLeadsFunnelStepByDragAndDropFulfilledExtraReducer
    );
    builder.addCase(
      changeEntityFunnelStepByDragAndDropAsyncThunk.rejected,
      (state, action) => {
        console.log(action.error);
      }
    );
    builder.addCase(
      getFunnelWithRelatedFunnelStepsWithoutRelatedLeadsAsyncThunk.fulfilled,
      funnelStepExtraReducers.getFunnelWithRelatedFunnelStepsWithoutRelatedLeadsFulfilledExtraReducer
    );
    builder.addCase(
      changeFunnelStepOrderByDragAndDropAsyncThunk.fulfilled,
      funnelStepExtraReducers.changeFunnelStepOrderByDragAndDropFulfilledExtraReducer
    );
    builder.addCase(
      changeFunnelStepOrderByDragAndDropAsyncThunk.rejected,
      (state, action) => {
        console.log(action.payload?.message);
      }
    );
    builder.addCase(
      updateFunnelStepNameAsyncThunk.fulfilled,
      funnelStepExtraReducers.updateFunnelStepNameFulfilledExtraReducer
    );
    builder.addCase(updateFunnelStepNameAsyncThunk.rejected, (state, action) => {
      console.log(action.error.message);
    });

    builder.addCase(
      deleteFunnelStepAsyncThunk.fulfilled,
      funnelStepExtraReducers.deleteFunnelStepFulfilledExtraReducer
    );
    builder.addCase(deleteFunnelStepAsyncThunk.rejected, (state, action) => {
      console.log(action.error.message);
    });
  },
});

export const {
  updateFunnelStepWithRealatedEntitiesCompanyNameAndFunnelStepSSU,
  setUndefinedWhenLeavingPage,
  removeDeletedEntityFromFunnelStepWithRelatedEntitiesSSU,

  // FUNNELS' PAGE WITH FUNNEL STEPS WITH RELATED LEADS
  addNewFunnelStepWithRelatedLeadsToFunnelStepsArraySSU,
  updateFunnelStepWithRelatedLeadsNameSSU,
  updateFunnelStepsOrderAtFunnelStepsWithRelatedLeadsSSU,
  deleteFunnelStepWithRelatedLeadsFromFunnelStepsArraySSU,

  // EDIT FUNNEL PAGE
  addFunnelStepToFunnelStepsWithoutRelatedLeadsArraySSU,
  updateFunnelStepNameWithoutRelatedLeadsSSU,
  updateFunnelStepsOrderAtFunnelStepsWithoutRelatedLeadsSSU,
  deleteFunnelStepWithoutRelatedLeadsFromFunnelStepsArraySSU,

  addNewFunnelToFunnelsListSSU,
  updateFunnelNameInFunnelsListSSU,
  updateFunnelWithFunnelStepsWithoutRelatedLeadsSSU,
  deleteFunnelFromFunnelsListSSU,
} = funnelSlice.actions;

export default funnelSlice.reducer;
