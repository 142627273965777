import { AppDispatch, RootState } from "../../../../../store";
import { CreateUpdateFunnelsListUpdateDataType } from "../../../types/funnelTypes/funnelsListTypes/funnelsListTypes";
import {
  updateFunnelNameInFunnelsListSSU,
  updateFunnelWithFunnelStepsWithoutRelatedLeadsSSU,
} from "../../../../funnel/store/slice";
import {
  updateLeadItemFunnelAndFunnelStepNamesSSU,
  updateLeadSSU,
} from "../../../../lead/store/slice";
import { LeadFunnelType } from "../../../../lead/types/leadFunnelTypes";

export const updateFunnelSystemUpdateHandler = (args: {
  eventData: CreateUpdateFunnelsListUpdateDataType;
  dispatch: AppDispatch;
  state: RootState;
}) => {
  const ssuData = {
    funnelName: args.eventData.ssuData.funnelName,
    funnelId: args.eventData.ssuData.funnelId,
  };

  if (args.state.funnel.funnelsList) {
    args.dispatch(updateFunnelNameInFunnelsListSSU(ssuData));
  } else if (
    // FUNNELS LIST
    args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities &&
    args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities.funnelId ===
      args.eventData.ssuData.funnelId
  ) {
    args.dispatch(updateFunnelWithFunnelStepsWithoutRelatedLeadsSSU(ssuData));
  } else if (
    args.state.lead.lead &&
    args.state.lead.lead.funnelStep?.funnel.funnelId ===
      args.eventData.ssuData.funnelId
  ) {
    // LEAD
    const updateLeadFunnelStepData = {
      funnelStepUuid: args.state.lead.lead.funnelStep.funnelStepUuid,
      funnelStepName: args.state.lead.lead.funnelStep.funnelStepName,
      funnel: {
        funnelId: args.state.lead.lead.funnelStep.funnel.funnelId,
        funnelName: args.eventData.ssuData.funnelName,
      },
    };

    args.dispatch(updateLeadSSU({ funnelStep: updateLeadFunnelStepData }));
  } else if (args.state.lead.leadListData?.leadsList) {
    const updateLeadListItemFunnelData: LeadFunnelType = {
      funnelId: args.eventData.ssuData.funnelId,
      funnelName: args.eventData.ssuData.funnelName,
    };

    args.dispatch(
      updateLeadItemFunnelAndFunnelStepNamesSSU(updateLeadListItemFunnelData)
    );
  }
};
