import _history from "../../../../../const/history";
import { AppDispatch, RootState } from "../../../../../store";
import { deleteFunnelFromFunnelsListSSU } from "../../../../funnel/store/slice";
import {
  updateLeadFunnelStepSSU,
  updateLeadItemFunnelAndFunnelStepNamesSSU,
} from "../../../../lead/store/slice";
import { FunnelOrFunnelStepHasBeedDeletedType } from "../../../../lead/types/leadFunnelTypes";

export const deleteunnelSystemUpdateHandler = (args: {
  state: RootState;
  dispatch: AppDispatch;
  funnelId: number;
}) => {
  if (args.state.funnel.funnelsList) {

    args.dispatch(
      deleteFunnelFromFunnelsListSSU({ identifier: args.funnelId })
    );
  }
  if (
    args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities &&
    args.state.funnel.funnelWithFunnelStepsWithoutRelatedEntities.funnelId ===
      args.funnelId
  ) {
    if (_history.navigate) {
      _history.navigate("/funnels/funnels-list");
    }
  }
  if (
    args.state.lead.lead &&
    args.state.lead.lead.funnelStep &&
    args.state.lead.lead.funnelStep.funnel.funnelId === args.funnelId
  ) {
    args.dispatch(updateLeadFunnelStepSSU(null));
  } else if (args.state.lead.leadListData?.leadsList) {
    const deletedSunnelStepData: FunnelOrFunnelStepHasBeedDeletedType = {
      funnelId: args.funnelId,
      nullValue: null,
    };
    args.dispatch(
      updateLeadItemFunnelAndFunnelStepNamesSSU(deletedSunnelStepData)
    );
  }
};
