import { createSlice } from "@reduxjs/toolkit";
import { customFieldIinitialState } from "./initialState";
import { customFieldExtraReducers } from "./extraReducers/fulfilledExtraReducers";
import {
  createCustomFieldAsyncThunk,
  updateCustomFieldAsyncThunk,
  deleteCustomFieldAsyncThunk,
} from "./asyncThunks";
import { customFieldsReducers } from "./reducers";
import { customFieldRejectedExtraReducers } from "./extraReducers/rejectedExtraReducers";

const customFieldSlice = createSlice({
  name: "customField",
  initialState: customFieldIinitialState,
  reducers: {
    setCustomFields: customFieldsReducers.setCustomFieldsReducer,
    setCustomFieldsStateUndefinedWhenLeavingLeadOrDealPage:
      customFieldsReducers.setCustomFieldsStateUndefinedWhenLeavingLeadOrDealPageReducer,
    // Websocket server sent updates reducers
    createCustomFieldSSU:
      customFieldExtraReducers.createCustomFieldExtraReducer,
    updateCustomFieldSSU:
      customFieldExtraReducers.updateCustomFieldExtraReducer,
    deleteCustomFieldSSU:
      customFieldExtraReducers.deleteCustomFieldExtraReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(
      createCustomFieldAsyncThunk.fulfilled,
      customFieldExtraReducers.createCustomFieldExtraReducer
    );
    // builder.addCase(createCustomFieldAsyncThunk.rejected, (state, action) => {
    //   console.log(action.error.message);
    // });

    builder.addCase(
      updateCustomFieldAsyncThunk.fulfilled,
      customFieldExtraReducers.updateCustomFieldExtraReducer
    );
    builder.addCase(
      updateCustomFieldAsyncThunk.rejected,
      customFieldRejectedExtraReducers.updateDeleteCustomFieldRejectedExtraReducer
    );

    builder.addCase(
      deleteCustomFieldAsyncThunk.fulfilled,
      customFieldExtraReducers.deleteCustomFieldExtraReducer
    );
    builder.addCase(
      deleteCustomFieldAsyncThunk.rejected,
      customFieldRejectedExtraReducers.updateDeleteCustomFieldRejectedExtraReducer
    );
  },
});

export const {
  setCustomFields,
  setCustomFieldsStateUndefinedWhenLeavingLeadOrDealPage,

  createCustomFieldSSU,
  updateCustomFieldSSU,
  deleteCustomFieldSSU,
} = customFieldSlice.actions;

export default customFieldSlice.reducer;
