import { PayloadAction } from "@reduxjs/toolkit";
import { RecordInitialStateType } from "../types/initialStateType";

const setRecordsReducer = (
  state: RecordInitialStateType,
  action: PayloadAction<RecordInitialStateType>
) => {
  state.containerId = action.payload.containerId;
  state.relatedEntityData = action.payload.relatedEntityData;
  state.records = action.payload.records;
};

const setRecordsInitialStateUndefinedWhenLeavingLeadOrDealPageReducer = (
  state: RecordInitialStateType,
  action: PayloadAction<undefined>
) => {
  state.containerId = undefined;
  state.relatedEntityData = undefined;
  state.records = undefined;
};

export const recordReducers = {
  setRecordsReducer,
  setRecordsInitialStateUndefinedWhenLeavingLeadOrDealPageReducer,
};
