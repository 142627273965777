import { createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateDealPersonsInChargeListType } from "../../types/dealPersonInChargeTypes";
import { AppDispatch } from "../../../../store";
import { dealPersonInChargeRequests } from "../../requests/dealPersonInChargeRequests";
import { showNotification } from "../../../notification/store/slice";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";

export const updateDealPersonsInChargeAsyncThunk = createAsyncThunk<
  string[],
  UpdateDealPersonsInChargeListType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>(
  "dealPersonsInCharge/updateDealPersonsInChargeAsyncThunk",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await dealPersonInChargeRequests.updateDealPersonsInCharge(args);
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Список ответственных обновлён",
        })
      );
      return response;
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText:
              "Сделка была удалена. Обновление данных невозможно.",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);
