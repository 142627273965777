import { PayloadAction } from "@reduxjs/toolkit";
import { FunnelInitialStateType } from "../../types/initialStateTypes";
import { FunnelWithRelatedFunnelStepsWithoutRelatedEntitiesType } from "../../types/funnelTypes";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import { SimplifiedEntityType } from "../../types/funnelLeadTypes";
import {
  EditSimplifiedEntityOfFunnelStepType,
  FunnelStepWithRelatedEntitiesType,
  FunnelStepWithoutRelatedEntitiesType,
  FunnelStepUuidAndNameType,
} from "../../types/funnelStepTypes";
import { RelatedEntityTypeEnum } from "../../../../commonTypes/relatedEntityTypes";

const changeLeadsFunnelStepByDragAndDropFulfilledExtraReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<EditSimplifiedEntityOfFunnelStepType>
) => {
  if (state.funnelWithFunnelStepsWithRelatedEntities === undefined) {
    return;
  }

  // Find required funnel steps and place them into array, to avoid
  // unnecessary iteration throug funnelSteps array
  const requiredFunnelSteps =
    state.funnelWithFunnelStepsWithRelatedEntities.funnelSteps.filter(
      (funnelStep) =>
        funnelStep.funnelStepUuid === action.payload.currentFunnelStepUuid ||
        funnelStep.funnelStepUuid === action.payload.targetFunnelStepUuid
    );

  let currentFunnelStep: null | FunnelStepWithRelatedEntitiesType = null;

  // Find current funnel step. The rest funnel step is new lead's funnel step.
  for (let i = 0; i < requiredFunnelSteps.length; i++) {
    if (
      requiredFunnelSteps[i].funnelStepUuid ===
      action.payload.currentFunnelStepUuid
    ) {
      currentFunnelStep = requiredFunnelSteps.splice(i, 1)[0];
      break;
    }
  }
  if (currentFunnelStep === null) {
    throw new Error("Funnel step has not been found");
  }
  // target funnelstep is always the last element of the requiredFunnelStepsArray
  const targetFunnelStep = requiredFunnelSteps[0];

  // select type of related entity: lead or deal
  let entitiesCurrentArray = [];
  let entitiesTargetArray = [];

  switch (action.payload.currentEntityData.entityType) {
    case RelatedEntityTypeEnum.lead:
      entitiesCurrentArray = currentFunnelStep.leads;
      entitiesTargetArray = targetFunnelStep.leads;
      break;
    case RelatedEntityTypeEnum.deal:
      entitiesCurrentArray = currentFunnelStep.deals;
      entitiesTargetArray = targetFunnelStep.deals;
      break;
    default:
      const _exhaustiveCheck: never =
        action.payload.currentEntityData.entityType;
      throw new Error("Entity type does not exist");
  }

  let currentEntity: null | SimplifiedEntityType = null;
  for (let i = 0; i < entitiesCurrentArray.length; i++) {
    if (entitiesCurrentArray[i].id === action.payload.currentEntityData.id) {
      currentEntity = entitiesCurrentArray.splice(i, 1)[0];
    }
  }

  if (currentEntity === null) {
    throw new Error("Lead does not exists at funnel step");
  }

  entitiesTargetArray.push(currentEntity);
};

const createFunnelStepFulfilledExtraReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelStepWithoutRelatedEntitiesType[]>
) => {
  if (state.funnelWithFunnelStepsWithoutRelatedEntities === undefined) {
    throw new Error("Steps list is undefined");
  }

  state.funnelWithFunnelStepsWithoutRelatedEntities.funnelSteps =
    action.payload;
};

const getFunnelWithRelatedFunnelStepsWithoutRelatedLeadsFulfilledExtraReducer =
  (
    state: FunnelInitialStateType,
    action: PayloadAction<FunnelWithRelatedFunnelStepsWithoutRelatedEntitiesType>
  ) => {
    state.funnelWithFunnelStepsWithoutRelatedEntities = action.payload;
  };

const changeFunnelStepOrderByDragAndDropFulfilledExtraReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelStepWithoutRelatedEntitiesType[]>
) => {
  if (state.funnelWithFunnelStepsWithoutRelatedEntities === undefined)
    throw new Error("Steps list is undefined");

  state.funnelWithFunnelStepsWithoutRelatedEntities.funnelSteps =
    action.payload;
};

const updateFunnelStepNameFulfilledExtraReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelStepUuidAndNameType>
) => {
  try {
    if (state.funnelWithFunnelStepsWithoutRelatedEntities === undefined) {
      throw new Error("Steps list is undefined");
    }

    const updatedFunnel = findElementInListUsingBuiltInFindMethod({
      list: state.funnelWithFunnelStepsWithoutRelatedEntities.funnelSteps,
      lookForParam: "funnelStepUuid",
      lookForParamValue: action.payload.funnelStepUuid,
    });

    if (updatedFunnel === null) {
      throw new Error("No step with required id");
    }
    updatedFunnel.funnelStepName = action.payload.funnelStepName;
  } catch (error) {
    console.log(error);
  }
};

const deleteFunnelStepFulfilledExtraReducer = (
  state: FunnelInitialStateType,
  action: PayloadAction<FunnelStepWithoutRelatedEntitiesType[]>
) => {
  try {
    if (state.funnelWithFunnelStepsWithoutRelatedEntities === undefined) {
      throw new Error("Steps list is undefined");
    }
    state.funnelWithFunnelStepsWithoutRelatedEntities.funnelSteps =
      action.payload;
  } catch (error) {
    console.log(error);
  }
};

export const funnelStepExtraReducers = {
  changeLeadsFunnelStepByDragAndDropFulfilledExtraReducer,
  createFunnelStepFulfilledExtraReducer,
  getFunnelWithRelatedFunnelStepsWithoutRelatedLeadsFulfilledExtraReducer,
  changeFunnelStepOrderByDragAndDropFulfilledExtraReducer,
  updateFunnelStepNameFulfilledExtraReducer,
  deleteFunnelStepFulfilledExtraReducer,
};
