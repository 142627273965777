import { AppDispatch, RootState } from "../../../../../store";
import { showNotification } from "../../../../notification/store/slice";
import { updateRecordSSU } from "../../../../record/store/slice";
import { deleteTask, updateTask } from "../../../../user/store/slice";
import { NotificationTypeEnum } from "../../../../notification/types/notificationTypes";
import {
  RelatedEntityDataType,
  RelatedEntityTypeEnum,
} from "../../../../../commonTypes/relatedEntityTypes";
import {
  RecordTaskCompletionStatusEnum,
  RecordType,
  RecordTypeWithRecordsContainerId,
} from "../../../../record/types/recordTypes";
import { RelatedEntityDataWIthoutRecordsContainerId } from "../../../types/recordTypes/recordDataTypes/recordDataTypes";

export const updateRecordSystemUpdateHandler = (args: {
  dispatch: AppDispatch;
  state: RootState;
  // data
  recordRelatedEntityData: RelatedEntityDataWIthoutRecordsContainerId;
  recordData: RecordTypeWithRecordsContainerId;
  // flags
  recordIsRelatedToOpenedLeadOrDeal: boolean;
  isTaskFlag: boolean;
  taskIsRelatedToUserFlag: boolean;
}) => {
  const leadData =
    args.recordRelatedEntityData.relatedEntityType ===
    RelatedEntityTypeEnum.lead
      ? {
          id: args.recordRelatedEntityData.relatedEntityId,
          name: args.recordRelatedEntityData.relatedEntityName,
        }
      : null;
  const dealData =
    args.recordRelatedEntityData.relatedEntityType ===
    RelatedEntityTypeEnum.deal
      ? {
          id: args.recordRelatedEntityData.relatedEntityId,
          name: args.recordRelatedEntityData.relatedEntityName,
        }
      : null;

  if (args.isTaskFlag && args.taskIsRelatedToUserFlag) {
    args.dispatch(
      showNotification({
        notificationText: `Текущая задача была обновлена. ${args.recordData.recordText}.
              Время выполнения: ${args.recordData.executionTime}. Статус: ${args.recordData.completionStatus}`,
        notificationType: NotificationTypeEnum.notification,
      })
    );
    if (
      args.recordData.completionStatus ===
      RecordTaskCompletionStatusEnum.completed
    ) {
      args.dispatch(
        deleteTask({
          completionStatus: args.recordData.completionStatus!,
          executionTime: args.recordData.executionTime!,
          id: args.recordData.id,
          recordText: args.recordData.recordText,
          leadData: leadData,
          dealData: dealData,
        })
      );
    }
    args.dispatch(
      updateTask({
        completionStatus: args.recordData.completionStatus!,
        executionTime: args.recordData.executionTime!,
        id: args.recordData.id,
        recordText: args.recordData.recordText,
        leadData: leadData,
        dealData: dealData,
      })
    );
  }

  if (args.recordIsRelatedToOpenedLeadOrDeal) {
    args.dispatch(
      updateRecordSSU({
        recordId: args.recordData.id,
        recordData: {
          recordText: args.recordData.recordText,
          completionStatus: args.recordData.completionStatus || undefined,
          executionTime: args.recordData.executionTime || undefined,
          personInChargeUsername:
            args.recordData.personInChargeUsername || undefined,
        },
        recordType: args.recordData.recordType,
      })
    );
  }
};
