import { axiosInstance } from "../../../api/httpCommon";
import camelize from "../../../utils/camelize";
import snakeize from "../../../utils/snakeize";
import {
  PersonInChargePreviewType,
  UpdateLeadPersonsInChargeDataAndLeadId,
} from "../types/leadPersonInChargeTypes";

export const getUsernamesForSelectUserList = async (args: {
  requiredUsernameOrItsPart: string;
}): Promise<PersonInChargePreviewType[]> => {
  const response = await axiosInstance.get(
    `/user/get-usernames?username=${args.requiredUsernameOrItsPart}`
  );
  return response.data;
};

export const updateLeadPersonsInChargeList = async (
  args: UpdateLeadPersonsInChargeDataAndLeadId
): Promise<string[]> => {
  const snakeizedData = snakeize(args.updateData);

  const response = await axiosInstance.patch(
    `/lead-person-in-charge/update-lead-persons-in-charge-list/${args.leadId}?records_container_id=${args.recordsContainerId}`,
    snakeizedData
  );

  const camelizedData = camelize(response.data);

  return camelizedData;
};

export const leadPersonInChargeRequests = {
  getUsernamesForSelectUserList,
  updateLeadPersonsInChargeList,
};
