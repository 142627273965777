import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  LeadsListAndPaginationDataType,
} from "../../types/leadTypes";
import { leadRequests } from "../../requests/leadRequests";
import { AppDispatch } from "../../../../store";
import { showNotification } from "../../../notification/store/slice";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";

export const getLeadListAsyncThunk = createAsyncThunk<
  LeadsListAndPaginationDataType,
  { pageNumber: number; limit: number; leadName?: string },
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("lead/getLeadList", async (args, { rejectWithValue, dispatch }) => {
  try {
    const leadsListAndPaginationData = await leadRequests.getLeadList({
      limit: args.limit,
      pageNumber: args.pageNumber,
      leadName: args.leadName,
    });

    return leadsListAndPaginationData;
  } catch (error: any) {
    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText:
          "На сервере произошла ошибка. Пожалуйста перезагрузите страницу.",
      })
    );
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});
