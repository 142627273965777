import { AppDispatch, RootState } from "../../../store";
import camelize from "../../../utils/camelize";
import { routeWebSocketUpdates } from "../routes/commonRouter";

export const generateOnMessageHandler = (args: {
  getState: () => RootState;
  dispatch: AppDispatch;
}) => {
  return async (innerArgs: { event: any }) => {
    const user = args.getState().user.user;
    if (!user) {
      return;
    }

    const currentUserUsername = user.username;
    const snakeizedEventData = JSON.parse(innerArgs.event.data);

    const incomingUserUsername = snakeizedEventData.user_username;

    if (currentUserUsername === incomingUserUsername) {
      return;
    }

    const eventData = camelize(snakeizedEventData);

    routeWebSocketUpdates({
      eventData: eventData,
      getState: args.getState,
      dispatch: args.dispatch,
    });
  };
};
