import { RootState, AppDispatch } from "../../../store";
import { FunnelListSSUType } from "./funnelTypes/funnelsListTypes/funnelsListTypes";
import { FunnelWithFunnelStepsWithoutRelatedLeadsSSUType } from "./funnelTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes/funnelWithFunnelStepsWithoutRelatedLeadsTypes";
import { ContactSSUType } from "./contactTypes/contactTypes";
import { RecordSSUType } from "./recordTypes/recordDataTypes/recordDataTypes";
import { CustomFieldSSUType } from "./customFieldsTypes/customFieldTypes";
import { LeadDataSSUType } from "./leadTypes/leadDataTypes";
import { CreateDealSSUType, UpdateDealSSUType } from "./dealTypes/dealTypes";
import { DeleteDealType } from "../../deal/types/dealTypes";
import { LeadPreviewType } from "../../lead/types/leadTypes";
import { LeadPersonsInChargeListType } from "./leadPersonsInChargeTypes/leadPersonsInChargeTypes";
import { DealPersonsInChargeListType } from "./dealPersonsInChargeTypes/dealPersonsInChargeTypes";
import { ContactDealSSUType } from "./contactsDealTypes/contactDealTypes";

export enum EntityTypeEnum {
  lead = "lead",
  leadPersonsInCharge = "lead_persons_in_charge",
  deal = "deal",
  dealPersonsInCharge = "deal_persons_in_charge",
  funnel = "funnel",
  funnelStep = "funnel_step",
  user = "user",
  contact = "contact",
  contactDeal = "contact_deal",
  customField = "custom_field",
  record = "record",
}

export enum LeadSubEntityTypeEnum {
  leadData = "lead_data",
  leadContact = "lead_contact",
  leadCustomField = "lead_custom_field",
  leadRecordsContainer = "lead_records_container",
}

export enum FunnelSubEntityTypeEnum {
  funnelsList = "funnels_list",
  funnelWithFunnelStepsWithRelatedLeads = "funnel_with_funnel_steps_with_related_leads",
  funnelWithFunnelStepsWithoutRelatedLeads = "funnel_with_funnel_steps_without_related_leads",
}

export enum RecordSubEntityEnum {
  recordData = "record_data",
}

export enum UpdateTypeEnum {
  create = "create",
  update = "update",
  delete = "delete",
}

export type SSUType =
  | LeadDataSSUType
  | LeadPreviewType
  | LeadPersonsInChargeListType
  | CreateDealSSUType
  | DeleteDealType
  | UpdateDealSSUType
  | DealPersonsInChargeListType
  | ContactSSUType
  | ContactDealSSUType
  | CustomFieldSSUType
  | RecordSSUType
  | FunnelListSSUType
  | FunnelWithFunnelStepsWithoutRelatedLeadsSSUType;

export type ServerSystemUpdateType = {
  eventType: UpdateTypeEnum;
  userUsername: string;

  entityType: EntityTypeEnum;
  subEntityType: LeadSubEntityTypeEnum | FunnelSubEntityTypeEnum;
  ssuData: SSUType;
};

export type RoutersArgsType = {
  eventData: ServerSystemUpdateType;
  getState: () => RootState;
  dispatch: AppDispatch;
};
