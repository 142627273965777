import { EntityTypeEnum, RoutersArgsType } from "../types/types";
import { routeContactsUpdates } from "./contactRoutes/contactRouter";
import { routeContactDealUpdates } from "./contactsDealRouter/contactDealRouter";
import { routeCustomFieldsUpdates } from "./customFieldRoutes/customFieldRouter";
import { routeDealPersonsInChargeUpdates } from "./dealPersonsInChargeRoutes/dealPersonsInChargeRouter";
import { routeDealUpdates } from "./dealRoutes/dealRouter";
import { routeFunnelUpdates } from "./funnelRoutes/funnelRouter";
import { routeFunnelStepsUpdates } from "./funnelRoutes/funnelStepRouter";
import { routeLeadPersonsInChargeUpdates } from "./leadPersonsInChargeRoutes/leadPersonsInChargeRouter";
import { routeLeadUpdates } from "./leadRoutes/leadRouter";
import { routeRecordUpdates } from "./recordRoutes/recordRouter";

export const routeWebSocketUpdates = (args: RoutersArgsType) => {
  switch (args.eventData.entityType) {
    case EntityTypeEnum.lead:
      routeLeadUpdates(args);
      break;

    case EntityTypeEnum.leadPersonsInCharge:
      routeLeadPersonsInChargeUpdates(args);
      break;

    case EntityTypeEnum.deal:
      routeDealUpdates(args);
      break;

    case EntityTypeEnum.dealPersonsInCharge:
      routeDealPersonsInChargeUpdates(args);
      break;
    // /////////////////////////
    case EntityTypeEnum.funnel:
      routeFunnelUpdates(args);
      break;

    case EntityTypeEnum.funnelStep:
      routeFunnelStepsUpdates(args);
      break;
    // /////////////////////////
    case EntityTypeEnum.user:
      break;
    // /////////////////////////
    case EntityTypeEnum.contact:
      routeContactsUpdates(args);
      break;

    case EntityTypeEnum.contactDeal:
      routeContactDealUpdates(args);
      break;
    // /////////////////////////
    case EntityTypeEnum.customField:
      routeCustomFieldsUpdates(args);
      break;

    case EntityTypeEnum.record:
      routeRecordUpdates(args);
      break;
    // /////////////////////////

    default:
      const _exhaustiveCheck: never = args.eventData.entityType;
      break;
  }
};
