import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../../../store";
import { AxiosError } from "axios";
import { dealRequests } from "../../requests/dealRequests";
import { setRecords } from "../../../record/store/slice";
import { setContacts } from "../../../contact/store/slice";
import { setCustomFields } from "../../../customField/store/slice";
import { RelatedEntityTypeEnum } from "../../../../commonTypes/relatedEntityTypes";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import { showNotification } from "../../../notification/store/slice";
import {
  CreateDealResponseType,
  CreateDealType,
  DealType,
  UpdateDealDataAndAdditionalDataType,
  UpdateDealRequestType,
  UpdateDealType,
} from "../../types/dealTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";

export const getDealByIdAsyncThunk = createAsyncThunk<
  DealType,
  { dealId: number },
  { rejectValue: AxiosError; dispatch: AppDispatch }
>("deal/getDealByIdAsyncThunk", async (args, { rejectWithValue, dispatch }) => {
  try {
    const response = await dealRequests.getDealById(args);

    dispatch(
      setRecords({
        containerId: response.recordsContainer.id,
        relatedEntityData: {
          relatedEntityName: response.name,
          relatedEntityId: response.id,
          relatedEntityType: RelatedEntityTypeEnum.deal,
          recordsContainerId: response.recordsContainer.id,
        },
        records: response.recordsContainer.records,
      })
    );

    dispatch(
      setContacts({
        contactsContainerId: response.contactsContainerId,
        contacts: response.contacts,
      })
    );

    dispatch(
      setCustomFields({
        containerId: response.customFieldsContainer.id,
        relatedEntityData: {
          relatedEntityName: response.name,
          relatedEntityId: response.id,
          relatedEntityType: RelatedEntityTypeEnum.deal,
          recordsContainerId: response.recordsContainer.id,
        },
        customFields: response.customFieldsContainer.customFields,
      })
    );

    const dealData: DealType = {
      id: response.id,
      name: response.name,
      comment: response.comment,
      startDate: response.startDate,
      finishDate: response.finishDate,
      leadId: response.leadId,
      lead: {
        name: response.lead.name,
      },
      funnelStep: response.funnelStep,
      personsInCharge: response.personsInCharge,
      dealContactsIds: response.contacts.map((contact) => contact.id),
      contactsContainerId: response.contactsContainerId,
      dealsOfTheSameLeadPreviews: response.dealsOfTheSameLeadPreviews,
    };

    return dealData;
  } catch (error: any) {
    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.error,
        notificationText:
          "Не удалось загрузить данные. Пожалуйста обновите страницу или свяжитесь с администратором",
      })
    );
    return rejectWithValue(error);
  }
});

export const createDealAsyncThunk = createAsyncThunk<
  CreateDealResponseType,
  CreateDealType,
  {
    rejectValue: RejectWithValueErrorType;
    state: RootState;
    dispatch: AppDispatch;
  }
>("deal/createDeal", async (args, { rejectWithValue, getState, dispatch }) => {
  try {
    // const user = getState().user.user;
    // if (!user || !user.username) {
    //   throw new Error("User not found. Please contact with administarator");
    // }

    const response = await dealRequests.createDeal(args);

    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Создана новая сделка",
      })
    );
    return response;
  } catch (error: any) {
    if (
      error.response.status === 409 &&
      error.response?.data?.error ===
        "Row with foreign key you trying to insert does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.error,
          notificationText: "Связаный лид был удалён.",
        })
      );
    }

    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
    });
  }
});

export const updateDealAsyncThunk = createAsyncThunk<
  UpdateDealType,
  UpdateDealDataAndAdditionalDataType,
  { rejectValue: RejectWithValueErrorType; dispatch: AppDispatch }
>("deal/updateDeal", async (args, { rejectWithValue, dispatch }) => {
  try {
    const updateData: UpdateDealRequestType = {
      funnelStepUuid: args.updateData.funnelStep?.funnelStepUuid || null,
      finishDate: args.updateData.finishDate || null,
      name: args.updateData.name || null,
      comment: args.updateData.comment || null,
    };

    await dealRequests.updateDeal({
      updatedEntityId: args.additionalData.dealId,
      updateData: updateData,
      recordsContainerId: args.additionalData.recordsContainerId,
    });
    dispatch(
      showNotification({
        notificationType: NotificationTypeEnum.success,
        notificationText: "Данные сделки успешно обновлены.",
      })
    );

    return args.updateData;
  } catch (error: any) {
    if (
      error.response.status === 404 &&
      error.response.data.error === "Data does not exist"
    ) {
      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.warning,
          notificationText: "Сделка была удалена",
        })
      );
    }
    return rejectWithValue({
      message: error.message,
      status: error.response?.status,
      errorDetails: error.response?.data?.error,
      entityId: args.additionalData.dealId,
    });
  }
});

// export const deleteDealAsyncThunk = createAsyncThunk<
//   CreateUpdateDeleteReturnOneValueType<number>,
//   DeleteDealType,
//   { rejectValue: Error | AxiosError; dispatch: AppDispatch }
// >("deal/deleteDeal", async (args, { rejectWithValue, dispatch }) => {
//   try {
//     const response = await dealRequests.deleteDeal(args);
//     dispatch(
//       deleteDealFromLeadDealsListSSU({
//         identifier: args.id
//       })
//     )
//     dispatch(
//       showNotification({
//         notificationType: NotificationTypeEnum.warning,
//         notificationText: "Сделка удалена",
//       })
//     );

//     return response;
//   } catch (error: any) {
//     dispatch(
//       showNotification({
//         notificationType: NotificationTypeEnum.error,
//         notificationText:
//           "Произошла ошибка. Пожалуйста перезагрузите страницу или свяжитесь с администратором.",
//       })
//     );
//     console.log(error);
//     return rejectWithValue(error);
//   }
// });
