import { UnknownObjectType } from "../commonTypes/unknownObjectType";

export const findElementInListUsingBuiltInFindMethod = <
  T extends UnknownObjectType
>(args: {
  list: T[];
  lookForParam: string;
  lookForParamValue: number | string | null;
}): T | null => {
  if (!(args.list[0] instanceof Object)) {
    throw new Error(
      "Validation error! List item of list argument is not object's type"
    );
  }

  const foundElement = args.list.find(
    (element) => element[args.lookForParam] === args.lookForParamValue
  );

  if (!foundElement) {
    return null;
  }

  return foundElement;
};
