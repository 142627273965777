import { PayloadAction } from "@reduxjs/toolkit";
import { ContactInitialStateType } from "../types/initialStateType";

const setContactsReducer = (
  state: ContactInitialStateType,
  action: PayloadAction<ContactInitialStateType>
) => {
  state.contactsContainerId = action.payload.contactsContainerId;
  // state.relatedEntityData = action.payload.relatedEntityData;
  state.contacts = action.payload.contacts;
};

const setContactsStateUndefinedWhenLeavingLeadOrDealPageReducer = (
  state: ContactInitialStateType,
  action: PayloadAction<undefined>
) => {
  state.contactsContainerId = undefined;
  // state.relatedEntityData = undefined;
  state.contacts = undefined;
};

export const constactReducers = {
  setContactsReducer,
  setContactsStateUndefinedWhenLeavingLeadOrDealPageReducer,
};
