import { UnknownObjectType } from "../commonTypes/unknownObjectType";

export const clearDataFromNullUndefinedFields = <
  T extends UnknownObjectType
>(args: {
  dataObject: T;
}): Partial<T> => {
  /*
    Removes all null and undefined fields from incoming object.
    DO NOT mutate the object.
    Returns new object without null and undefined fields.

    arg: Object
    return: Object
  */
  if (!(args.dataObject instanceof Object)) {
    throw new Error("Validation error! Provided argument is not object's type");
  }

  const updateRecordDataKeys = Object.keys(args.dataObject);
  const clearedFromNullAndUndefinedRecordData: UnknownObjectType = {};

  updateRecordDataKeys.forEach((key) => {
    if (
      args.dataObject[key as keyof UnknownObjectType] !== undefined &&
      args.dataObject[key as keyof UnknownObjectType] !== null
    ) {
      clearedFromNullAndUndefinedRecordData[key] = args.dataObject[
        key as keyof UnknownObjectType
      ] as string;
    }
  });

  return clearedFromNullAndUndefinedRecordData as Partial<T>;
};
