import { PayloadAction } from "@reduxjs/toolkit";
import { DealInitialStateType } from "../../types/initialStateType";

const updateDealPersonsInChargeExtraReducer = (
  state: DealInitialStateType,
  action: PayloadAction<string[]>
) => {
  if (!state.deal) {
    return;
  }
  state.deal.personsInCharge = action.payload;
};


export const dealPersonInChargeReducers = {updateDealPersonsInChargeExtraReducer}