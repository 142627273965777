import { AppDispatch, RootState } from "..";
import { generateOnMessageHandler } from "../../apps/webSocket/utils/eventHandlers";
import { WebsocketConnector } from "../../apps/webSocket/websocketConnector";

import { Socket } from "../../apps/webSocket/utils/webSocketManager";
import { MiddlewareAPI } from "redux";
import { baseURL } from "../../api/const";

export const notificationWebSocketsMiddleware =
  (socket: Socket) => (api: MiddlewareAPI) => (next: any) => (action: any) => {
    const getState = api.getState as () => RootState;
    const dispatch = api.dispatch as AppDispatch;

    switch (action.type) {
      case "socket/connect":
        const accessToken = localStorage.getItem("access");
        if (!accessToken) {
          break;
        }
        const onMessageHandler = generateOnMessageHandler({
          dispatch: dispatch,
          getState: getState,
        });

        const websocketConnectionURL = `${baseURL}ws/?access=${accessToken}`;
        const webSocketConnection = new WebsocketConnector({
          connectionURL: websocketConnectionURL,
          maxAttempts: 5,
          onMessageCallback: onMessageHandler,
        });

        webSocketConnection.connectWebSocket();

        break;

      case "socket/disconnect":
        socket.disconnect();
        break;
      default:
        break;
    }

    return next(action);
  };
