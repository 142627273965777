import { PayloadAction } from "@reduxjs/toolkit";
import { CustomFieldInitialStateType } from "../../types/initialStateType";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import { deleteListItemUsingForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";

const updateDeleteCustomFieldRejectedExtraReducer = (
  state: CustomFieldInitialStateType,
  action: PayloadAction<RejectWithValueErrorType | undefined>
) => {
  if (!state.customFields) {
    throw new Error("Custom fields has not bean loaded");
  }

  if (
    action.payload &&
    action.payload.entityId &&
    action.payload.status === 404
  ) {
    const updatedContactsList = deleteListItemUsingForAndSpliceMethods({
      filterParam: "id",
      filterParamValue: action.payload.entityId,
      list: state.customFields,
    });

    if (updatedContactsList?.updatedArray) {
      state.customFields = updatedContactsList.updatedArray;
    }
  }
};

export const customFieldRejectedExtraReducers = {
  updateDeleteCustomFieldRejectedExtraReducer,
};
