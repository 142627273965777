import { RecordTaskCompletionStatusEnum } from "../../../record/types/recordTypes";
import { UserInitialStateType } from "../../types/initialStateType";
import { TasksSortedByExecutionTimeType } from "../../types/userTasksTypes";
import { UserWithTasksType } from "../../types/userTypes";

export const setUserData = (args: {
  state: UserInitialStateType;
  user: UserWithTasksType;
}) => {
  args.state.user = {
    avatar: args.user.avatar,
    email: args.user.email,
    id: args.user.id,
    name: args.user.name,
    phoneNumber: args.user.phoneNumber,
    position: args.user.position,
    tgUsername: args.user.tgUsername,
    username: args.user.username,
    isActive: args.user.isActive,
    isAdmin: args.user.isAdmin,
  };

  if (!args.user.tasks.length) {
    return;
  }

  const sortedTasksList: TasksSortedByExecutionTimeType = {
    overduedTasks: [],
    todayTasks: [],
    futureTasks: [],
  };

  args.user.tasks.forEach((task) => {
    if (task.completionStatus === RecordTaskCompletionStatusEnum.completed) {
      return;
    }

    const executionTime = new Date(task.executionTime);
    const currentDate = new Date();

    if (
      task.executionTime.substring(0, 10) ===
      currentDate.toISOString().substring(0, 10)
    ) {
      sortedTasksList.todayTasks.push(task);
      return;
    }
    if (executionTime < currentDate) {
      sortedTasksList.overduedTasks.push(task);
      return;
    }
    if (executionTime > currentDate) {
      sortedTasksList.futureTasks.push(task);
      return;
    }
  });

  args.state.userTasks = sortedTasksList;
};
